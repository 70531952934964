import { useState, useEffect, forwardRef } from "react"
import { Card, CardContent, Box, Typography, CardHeader, FormControlLabel, Checkbox } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import SafetyCheckIcon from '@mui/icons-material/SafetyCheck'

const useStyles = makeStyles({
    pageCentral: {
        minHeight: "100vh",
    },
    cardCentral: {
        padding: 0,
        width: '100%', //50vw
        margin: 'auto',
        marginTop: '10px',
        marginBottom: 20
    },
})


export default function UpdateChecker(props) {
    const classes = useStyles()

    const [isNotifiable, setIsNotifiable] = useState(true)

    const user = localStorage.getItem("user") !== null ? JSON.parse(localStorage.getItem("user")) : null;

    useEffect(() => {

        verifyUserUpdate()
    }, []);

    const verifyUserUpdate = () => {
        fetch(`${process.env.REACT_APP_URL}/usernotification/get/${user.id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                },
            })
                .then(response => response.json())
                .then(data => {
                    setIsNotifiable(data.booL_ISNOTIFIABLE)
                })
                .catch(error => {

                });
    }

    const optionUserUpdate = () => {
        localStorage.removeItem("lastCheckDate");
        setIsNotifiable(!isNotifiable);
        update(!isNotifiable);
    };

    //useEffect(() => {
    //    const updateUserNotification = async () => {
    //        try {
    //            const response = await fetch(`${process.env.REACT_APP_URL}/usernotification/put?id=${user.id}&isnotifiable=${isNotifiable}`, {
    //                method: "PUT",
    //                headers: {
    //                    "Content-Type": "application/json"
    //                },
    //            });

    //            if (response.ok) {
    //                console.log('UserNotification Success');
    //            } else {
    //                console.error('Failed to update user notification:', response.status);
    //            }
    //        } catch (error) {
    //            console.error('UserNotification Error:', error);
    //        }
    //    };

    //    if (isNotifiable !== undefined) {
    //        updateUserNotification();
    //    }
    //}, [isNotifiable]); 

    const update = (value) => {

        fetch(`${process.env.REACT_APP_URL}/usernotification/put?id=${user.id}&isnotifiable=${value}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            }
        });
    };

return(
    <Card className={classes.cardCentral}>
        <CardHeader
        avatar = {<SafetyCheckIcon color="primary"/>}
        title = {"Notificação de Atualização"}
        />        
        <Box marginTop={"10px"} marginBottom={"10px"} marginLeft={"50px"}>
        <FormControlLabel
         control={
             <Checkbox  
                        checked={isNotifiable}
                        onChange={optionUserUpdate}
                        inputProps={{ 'aria-label': 'controlled' }}
             />
         }
         label="Ativar recebimento das notificações de atualização"
     />
     </Box>
    </Card>

)

}