import EventCommandEnum from "../enums/EventCommandEnum";

class Event {
    constructor(id, command, equipamentName, equipamentId, local, memory, panel, position, datetime, origin) {
        this.id = id || null;
        this.command = this.validateCommand(command); // fK_OCORRENCIA
        this.equipamentName = equipamentName || ""; // dS_DESCRICAO_EVENTO
        this.equipamentId = equipamentId || ""; // dS_ID_PERIFERICO
        this.local = local || ""; // dS_LOCAL_EVENTO
        this.memory = memory || ""; // dS_POS_MEM
        this.panel = panel || sessionStorage.getItem("serial"); // fK_CENTRAL
        this.position = position || 0; // fK_GRUPO
        this.datetime = datetime instanceof Date ? datetime : new Date(datetime); // dT_EVENTO
        this.origin = origin || ""; // dS_ORIGEM_ALARME
    }

    // Valida o comando para garantir que ele pertence ao enum
    validateCommand(command) {
        if (!Object.values(EventCommandEnum).includes(command)) {
            throw new Error(`Invalid command: ${command}`);
        }
        return command;
    }
}

export default Event;