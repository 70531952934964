import React, { useState, useEffect } from "react"
import { Button, Box, Modal, Badge, Container, IconButton, styled, Stack, Paper, Grid, Typography } from "@mui/material"
import { Warning, Cancel } from "@mui/icons-material"
import makeStyles from "@mui/styles/makeStyles"
//import { Alert } from "@material-ui/lab"
import EventAlertRemote from "./EventAlertRemote"
import Drawer from "../includes/Drawer"

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "#5E5E5E",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    width: '100%',
    marginTop: "0px !important",
    marginBottom: "2px !important"
}));

const ItemHeader = styled(Paper)(({ theme }) => ({
    backgroundColor: "#B4B4B4",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: '#fff',
    width: '100%',
    marginBottom: "10px !important"
}));

const useStyles = makeStyles((theme) => {
    return {
        modalEvent: {
            position: 'absolute',
            top: '10%',
            left: '10%',
            overflow: 'scroll',
            height: 'auto',
            width: 'auto',
            display: 'block'
        },
        containerEvent: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        },
        closeEvent: {
            color: '#f44336',
            padding: '2rem'
        },
        messageEvent: {
            borderRadius: 10,
            width: "auto",
            height: "auto",
            position: "absolute",
            backgroundColor: "#FFFFFF",
            padding: "2rem"
        },
        containerEventModal: {
            alignItems: 'center',
            //justifyContent: 'center',
            [theme.breakpoints.up('sm')]: {
                display: 'grid',
                gridTemplateColumns: 'repeat(7, 1fr)',
                gap: 1,
                gridTemplateRows: 'auto',
                gridTemplateAreas: `"eventModalItem1 eventModalItem2 eventModalItem3 eventModalItem4 eventModalItem5 eventModalItem6 eventModalItem7"`,
            },
            [theme.breakpoints.down('sm')]: {
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                gap: 1,
                gridTemplateRows: 'auto',
                gridTemplateAreas: `"eventModalItem1 eventModalItem2 eventModalItem3" 
                "eventModalItem4 eventModalItem5 eventModalItem6"
                "eventModalItem7 . ."`,
            }
        },
        eventModalItem1: {
            gridArea: "eventModalItem1",
        },
        eventModalItem2: {
            gridArea: "eventModalItem2",
        },
        eventModalItem3: {
            gridArea: "eventModalItem3",
        },
        eventModalItem4: {
            gridArea: "eventModalItem4",
        },
        eventModalItem5: {
            gridArea: "eventModalItem5",
        },
        eventModalItem6: {
            gridArea: "eventModalItem6",
        },
        eventModalItem7: {
            gridArea: "eventModalItem7",
        }
    }
})

export default function EventModal(props) {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const [events, setEvents] = useState([])
    const [length, setLength] = useState()
    var [eventsCounter, setCommandCouner] = useState(1)

    const handleOpen = () => {
        setOpen(!open)
    }

    async function populate() {
        try {
            const res = await fetch(`${process.env.REACT_APP_URL}/events/group`, {
                method: 'POST',
                headers: {
                    "content-type": "application/json",
                    "Authorization": "Bearer " + props.token
                },
                body: JSON.stringify({ "groupId": props.groupId })
            });
            if (!res.ok) throw new Error(`HTTP error! status: ${res.status}`);
            const data = await res.json();
            setEvents(data);
        } catch (error) {
            console.error("Failed to fetch events:", error);
        }
    }
    

    // useEffect(() => {
    //     if (typeof props.rows !== 'undefined' && props.rows !== null && props.rows.fK_GRUPO === props.groupId && props.rows.type === 'evento') {
    //         var tempEvent = [...events]
    //         tempEvent.push(props.rows)
    //         setEvents(tempEvent)
    //     }
    // }, [props.rows])

    useEffect(() => {
        populate()
    }, [])

    return (
        <div>
            <label for={props.index} className="button button--xs mx-auto">
                <Badge
                    badgeContent={events.length > 0 ? events.length : 0}
                    color="secondary"
                    showZero={true}
                >
                    <i class="df-i df-i-2xl df-i-events df-i-white"></i>
                </Badge>
            </label>
            <Drawer className="drawer--bottom-full" drawerId={props.index}>
                <Drawer.Header>
                    <h3>{props.title}</h3>
                </Drawer.Header>
                <Drawer.Body>
                    <Container>
                        <Stack alignItems="center" spacing={2}>
                            <ItemHeader item key={0} xs={12} md={6} lg={4}>
                                <Box className={classes.containerEventModal}>
                                    <Box className={classes.eventModalItem1}><Warning /></Box>
                                    <Box className={classes.eventModalItem2}><Typography variant="body2">Central</Typography></Box>
                                    <Box className={classes.eventModalItem3}><Typography variant="body2">Ocorrência</Typography></Box>
                                    <Box className={classes.eventModalItem4}><Typography variant="body2">Descrição</Typography></Box>
                                    <Box className={classes.eventModalItem5}><Typography variant="body2">Id Periférico</Typography></Box>
                                    <Box className={classes.eventModalItem6}><Typography variant="body2">Local do Evento</Typography></Box>
                                    <Box className={classes.eventModalItem7}><Typography variant="body2">Data</Typography></Box>
                                </Box>
                            </ItemHeader>
                            {events.length > 0
                                ?
                                events.map((event) => (
                                    <Item item key={event.id} xs={12} md={6} lg={4}>
                                        <EventAlertRemote key={event.id} row={event} />
                                    </Item>
                                ))
                                :
                                <h4 style={{textAlign: "center"}}>Este grupo não possui eventos.</h4>
                            }
                        </Stack>
                    </Container>
                </Drawer.Body>
            </Drawer>
        </div>
    )
}