import AlarmCommandEnum from "../enums/AlarmCommandEnum";

class Alarm {
    constructor(id = null, userId, groupValue, groupName, command, origin, datetime) {
        this.id = id || null;
        this.userId = userId || ""; //CompanyId
        this.groupValue = groupValue || 0; // 0 para Geral
        this.groupName = groupName || "";
        this.command = this.validateCommand(command); // Verifica o comando
        this.origin = origin || ""; // Identifica a origem do comando
        this.datetime = datetime instanceof Date ? datetime : new Date(datetime); // dT_EVENTO || dT_ALARME
    }

    // Valida o comando para garantir que ele pertence ao enum
    validateCommand(command) {
        if (!Object.values(AlarmCommandEnum).includes(command)) {
            throw new Error(`Invalid command: ${command}`);
        }
        return command;
    }

    // Métodos úteis
    isGeneral() {
        return this.groupValue.slice(12) === "0";
    }

    isDeactivateCommand() {
        return this.command === AlarmCommandEnum.RESTAURAR;
    }
}

export default Alarm;