import { React, useState, useEffect, useCallback } from 'react'
import { BrowserRouter as Router, Route, useHistory } from 'react-router-dom'
import Amplifiers from './pages/Amplifiers'
import Groups from './pages/Groups'
import Registers from './pages/Registers'
import ReceiveData from './pages/Events'
import Alarms from './pages/Alarms'
import Commands from './pages/Commands'
import Central from './pages/Central'
import HomePage from './pages/HomePage'
import WebHomePage from './pages/WebHomePage'
import RessaleHomePage from './pages/RessaleHomePage'
import FirstLogin from './pages/FirstLogin'
import LayoutMaster from './components/LayoutMaster'
import EventsMaster from './pages/EventsMaster'
import User from './pages/User'
import UserNotAllowed from './pages/UserNotAllowed'
import ClientsPanel from './pages/ClientsPanel'
import { createTheme, responsiveFontSizes } from '@mui/material/styles'
import { ThemeProvider } from '@mui/material/styles'
import { purple, grey, lightBlue, orange, red } from '@mui/material/colors'
import Layout from '../src/components/Layout'
import Relatory from './pages/Relatory'
import Dasboards from './pages/Dasboards'
import UserWeb from './pages/UserWeb.js'
import CreateMultiAccountuser from './pages/CreateMultiAccountUser'
import ResalesLicences from './pages/ResalesLicences'
import ResaleUser from './pages/ResaleUser'
import TerminalFire from './pages/TerminalFire'
import OperationMode from './pages/OperationMode'
import TechnicianFirstLogin from './pages/TechnicianFirstLogin'
import WebConfiguration from './pages/WebConfiguration'
import { StylesProvider, createGenerateClassName } from '@material-ui/styles'
import CommandsProvider from './providers/CommandsProvider' // Importa a classe Commands
import CommandsPage from './pages/CommandsPage.js'
import groupsProvider from './providers/GroupsProvider.js'
import commandsProvider from './providers/CommandsProvider'

const generateClassName = createGenerateClassName({
  productionPrefix: 'delta',
});

const signaR = require('@microsoft/signalr');

function App() {
  const [userRole, setUserRole] = useState(null)
  const [serials, setSerials] = useState([])
  const [rows, setRows] = useState()
  const [clients, setClients] = useState([])
  const [systemData, setSystemData] = useState(localStorage.getItem("system") !== null ? JSON.parse(localStorage.getItem("system")) : [])
  const [userData, setUserData] = useState(localStorage.getItem("user") !== null ? JSON.parse(localStorage.getItem("user")) : {})
  const [token, setToken] = useState(localStorage.getItem("token") !== null ? localStorage.getItem("token") : "")
  const [menuItens, setAppMenuItens] = useState([])
  const [amplifierChange, setAmplifierChange] = useState({})
  const [newAmplifier, setNewAmplifier] = useState({})
  const [port, setPort] = useState("")
  const [modemStatus, setModemStatus] = useState(process.env.REACT_APP_MODE === "desktop" ? {status: false, checked: false} : {status: true, checked: false});
  const [modemConnected, setModemConnected] = useState("não informado");
  const connection = new signaR.HubConnectionBuilder().withUrl(`${process.env.REACT_APP_URL}/streaminghub`).withAutomaticReconnect().build()
  const [changeTheme, setChangeTheme] = useState(lightBlue[500])
  const [darkMode, setDarkMode] = useState(true)
  const palleteMode = darkMode ? 'dark' : 'light'
  const mainSecondaryColor = changeTheme
  const location = window.location.href
  const [technicianLicence, setTechnicianLicence] = useState(true)
  const [groupCommands, setGroupCommands] = useState([])
  const [clearBackdrop, setClearBackdrop] = useState()
  const [panelMode, setPanelMode] = useState("NÃO INFORMADO")
  const [panel, setPanel] = useState([])
  const [ressalePanelMonitoring, setResalePanelMonitoring] = useState()
  const [desktopStatus, setDesktopStatus] = useState("offline")
  const history = useHistory()
  const [fileUploaded, setFileUploaded] = useState(false)
  const [timer, setTimer] = useState(false);



  let theme = createTheme({
    palette: {
      mode: palleteMode,
      primary: grey,
      secondary: {
        main: mainSecondaryColor
      },
      default: purple
    },
    actions: {
      alarm: purple
    },
    error: {
      main: '#4dc8f4',
    },
    typography: {
      delta: {
        fontSize: '14px',
        fontFamily: 'SF Pro Regular',
        fontWeightLight: 400,
      },
      delta2: {
        fontSize: '12px',
        fontFamily: 'SF Pro Light',
        fontWeightLight: 400,
      },
      fontFamily: 'SF Pro',
      fontWeightLight: 400,
      fontWeightRegular: 500,
      fontWeightMedium: 600,
      fontWeightBold: 700
    }
  })


  theme = responsiveFontSizes(theme)

  theme.typography.delta = {
    [theme.breakpoints.down('sm')]: {
      fontSize: '8px'
    },
  }

  const logout = async () => {
    setUserRole();
    setSerials([]);
    setToken("");
    setUserData({});
    setSystemData([]);
    setRows(null);

    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("system");
    localStorage.removeItem('df-guardiao-type-view')
    localStorage.removeItem("noACDC");


    sessionStorage.removeItem("coordinator");
    sessionStorage.removeItem("amplifiers");
    sessionStorage.removeItem("serial");
    sessionStorage.removeItem("serialConnected");
    sessionStorage.removeItem("hubConnection");
    sessionStorage.removeItem("avoidSyncModem");

    
    if (process.env.REACT_APP_MODE === "desktop")
    {
      await closeApp();
    }
    else
    {
      clearCache();
    }
  }

  const clearState = () => {
    setRows()
  }

  const clearCache = () => {
    if('caches' in window){
      //alert("TEM CACHE");
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach(name => {
            caches.delete(name);
        })
      });
      // window.location.reload(true);
      window.location.href = "/";
    }
  }

  const closeApp = async () => {
    if (process.env.REACT_APP_MODE === "desktop")
    {
      try
      {
        if(!!userData.dS_NOME)
        {
          const response = await fetch(`${process.env.REACT_APP_URL}/account/logout`, {
            method: "POST",
            headers: {
              "content-type": "application/json",
              "Authorization": "Bearer " + token
            },
            body: JSON.stringify(userData)
          });
  
          if (!response.ok)
          {
            throw Error("Errou a fechar a aplicação.");
          }
          else
          {
            window.location.href("/");
          }
        }
        else
        {
          throw Error("Sem informações de usuário");
        }
      }
      catch (error)
      {
        console.log("🚀 ~ closeApp ~ error:", error);
      }
    }
  }

  if (location.split("/")[3] !== "" && userRole == null && location.split("/")[3] !== "cadastro+integrador" && location.split("/")[3] !== "cadastro+master" && location.split("/")[3] !== "modo+operacao") {
    logout()
  }

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const verifyIfUserExists = (serial, user) => {
        //const serial = sessionStorage.getItem("serial");
        console.log('\n\n Serial?:\n\n', serial);
        fetch(`${process.env.REACT_APP_URL}/semredeac/create-if-doesnt-exists?panelSerial=${serial}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(user)
        })
            .then(response => response.json())
            .then(data => {

            })
            .catch(error => {

            });
    }

  const populateData = async (user, newToken) => {
    //setSystemData([]);

    setUserRole(user.role)
    
    user.role.tecnico === true ? setChangeTheme(orange[500]) : user.role.revenda === true ? setChangeTheme(red[500]) : setChangeTheme(lightBlue[500])

    console.log("Porta COM em App.js: " + sessionStorage.getItem("serialPortNumber"))
    try
    {
      const response = await fetch(`${process.env.REACT_APP_URL}/UserFetchData/get?id=${user.iD_EMPRESA}&serialConnected=${sessionStorage.getItem("serialConnected")}&portName=${sessionStorage.getItem("serialPortNumber")}`, {
        method: 'GET',
        headers: {
          "content-type": "application/json",
          "Authorization": "Bearer " + newToken
        }
      });
      
      if (!response.ok)
      {
        throw new Error('Erro ao obter informações do sistema');
      }
      
      const res = await response.json();
      /*await sleep(400);*/

      setSystemData(res);
      localStorage.setItem("system", JSON.stringify(res))
      if (!res.serialPortError)
      {
        sessionStorage.setItem("serialConnected", "conected");
      }

      if (res.serialKeys !== null) {
          await getPanelMode(res.serialKeys);
          verifyIfUserExists(res.serialKeys, user);
      }
      else {
          let serialbk = sessionStorage.getItem("serial")
          verifyIfUserExists(serialbk, user);
          await getPanelMode(serialbk);
      }
      
      await groupsProvider.loadGroups();

      if (user.role.revenda === true || user.role.delta === true || user.role.multiCentral === true) 
      {
          getResaleInfo(user, user.newToken);
      }
      else 
      {
          if (res.serialKeys !== null) {
              hubConnectionSerial(res.serialKeys)
          }
          else
          {
              let serialbk = sessionStorage.getItem("serial");
              hubConnectionSerial(serialbk);
          }
      }
    }
    catch (error)
    {
      console.log("🚀 ~ populateData ~ error:", error)
    }
  }  

  const getPanelMode = async (serialBackUp) => {    
    const response = await fetch(`${process.env.REACT_APP_URL}/panel/last?centralSerial=${serialBackUp}`, {
        method: "GET",
        headers: {
            "content-type": "application/json",
            "authorization": `Bearer ${localStorage.getItem("token")}`
        }
    });

    if (!response.ok)
    {
        throw new Error("Falha ao buscar dados do Modo de Operação");
    }

    const res = await response.json();

    if (res.status !== "SEM REDE AC")
    {
        //setPanelMode(res.status);
        CommandsProvider.updatePanelMode(res.status);
    }
  }

  const getResaleInfo = async (user, newToken) => {
    try
    {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/licences/all?id_revenda=${user.iD_INTRANET}&cnpj=${user.dS_CNPJ}&multiCentral=${user.role.multiCentral}`, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                "Authorization": "Bearer " + newToken
            }
        });

        if (!response.ok) {
            throw new Error('Erro ao obter informações da Revenda');
        }

        const res = await response.json();

        if (res.length > 0)
        {
            var menuItensList = Array.from(new Set(
                res.filter((este, i) => res.indexOf(este) === i).map(item => {
                    return item;
                })
            ));

            setSerials(menuItensList);

            menuItensList.map(licence => (
                connection.on(licence.id_central, (message) => {
                    setRows(message);
                })
            ))

            menuItensList.map(licence => (
                connection.on(`panel${licence.id_central}`, (message) => {
                    setResalePanelMonitoring(message)
                })
            ))

            connection.start({ withCredentials: false }).then(() => { console.log(connection) });
        }

    }
    catch (error)
    {
      console.log("🚀 ~ getResaleInfo ~ error:", error)
    }
  }

  const hubConnectionLicence = () => {
    connection.on("licenceExpipired", (message) => {

      let newUserData = userData
      newUserData.role.light = true
      setUserData(newUserData)

      let newUserRole = userData.role
      newUserRole.light = true
      setUserRole(newUserRole)
    })
  }

  const hubConnectionAmplifier = () => {
    connection.on("amplifier", (message) => {

      if (process.env.REACT_APP_MODE === 'desktop') 
      {
        fetch(`${process.env.REACT_APP_API_URL}/amplifier/remote`, {
          method: 'POST',
          headers: {
            "content-type": "application/json",
            "Authorization": "Bearer " + token
        },
          body: JSON.stringify({
            "amplifierSerial": message.amplifier,
            "status": message.status
          })
        }).then().catch((error) => { console.log("🚀 ~ hubConnectionAmplifier ~ error:", error) } )
      }
      setAmplifierChange(message)
    })
  }

  const hubConnectionNewAmplifier = () => {
    connection.on("newAmplifier", (message) => {
      setNewAmplifier(message)
    })
  }

  const hubConnectionPanelMode = (serial) => {
    connection.on(`panelMode${serial}`, (message) => {
      //setPanelMode(message)
      CommandsProvider.updatePanelMode(message);
    })
  }

  const hubConnectionDesktopApplicationStatus = (serial) => {
      connection.on(`desktopStatus${serial}`, (message) => {
      setDesktopStatus(message)
    })
  }

  const hubConnectionSerial = (serial) => {
    if (sessionStorage.getItem("hubConnection") == null) {
      sessionStorage.setItem("hubConnection", true)
      connection.on(serial, (message) => {
        
        console.log("🚀 ~ hubConnection ~ serial.message:", message)
        
        if (message.type === 'alarme') 
        {
          // Processa o comando de alarme vindo da rede
          const alarm = CommandsProvider.convertMessageToEvent(message);
          CommandsProvider.updateCurrentAlarmState(alarm);
          CommandsProvider.processAlarm(alarm);
          //setRows(alarm);
        }
        else 
        {
          // Processa o comando de evento vindo da rede
          const event = CommandsProvider.convertMessageToEvent(message);
          CommandsProvider.updateGroupEventsState(event);
          //setRows(event);
        }
      })

      console.log("Serial de hubConnectionSerial: " + serial)
      
      //hubConnectionModemConnected()
      hubConnectionNewAmplifier()
      hubConnectionAmplifier()
      hubConnectionLicence()
      hubConnectionPanelMode(serial)
      hubConnectionDesktopApplicationStatus(serial)
      Start()
    }

  }

  const convertMessageToCommand = (message) => {
    var groupValue = message.fK_GRUPO.slice(12)

    if (groupValue === '0') {
      return {
        "groupValue": 'general',
        "type": message.fK_OCORRENCIA
      }
    } else {
      return {
        "groupValue": groupValue,
        "type": message.fK_OCORRENCIA
      }
    }
  }

  const generatedCommand = (generatedCommand) => {
    var tempGroupComands = []

    if (generatedCommand.groupValue === 'general') {
      tempGroupComands.push(generatedCommand)
      setGroupCommands(tempGroupComands)
    } else {
      tempGroupComands = [...groupCommands]
      tempGroupComands = tempGroupComands.filter(command => command.groupValue !== generatedCommand.groupValue)
      tempGroupComands.push(generatedCommand)
      setGroupCommands(tempGroupComands)
    }
    if (generatedCommand.type === 'DESL') {
      setGroupCommands([])
      setClearBackdrop(Math.random)
    }
  }

  async function Start() {
      try {
          await connection.start({ withCredentials: false }).then(() => { console.log(connection) });
          console.log("conectando com hub")
      } catch (err) {
          console.log(err)
          setTimeout(Start, 5000)
      }
  }

  const blockDevTools = useCallback((e) => {
    if (e.key === 'F12' || 
        (e.ctrlKey && e.shiftKey && e.key === 'I') || 
        (e.ctrlKey && e.shiftKey && e.key === 'C')) {
        e.preventDefault();
    }
  },[]);

  useEffect(() => {
    if (process.env.REACT_APP_ENVIROMENT === "production" && userData.dS_NOME !== "suporte03deltafire")
    {
      document.addEventListener('keydown', blockDevTools);
    }
    else
    {
      console.log("🔥 ~ BEM-VINDO - Estamos de olho 👀");
      document.removeEventListener('keydown', blockDevTools);
    }

      verifyUserShowSemRedeAC();

    // Limpar o listener quando o componente for desmontado ou o userData.dS_NOME mudar
    return () => {
      document.removeEventListener('keydown', blockDevTools);
    };
  }, [userData.dS_NOME, blockDevTools])

    //seta um timer para sincronizar possiveis ações em outras paginas
    useEffect(() => {
        if (timer) {
            const interval = setInterval(() => {
                setTimer(false)
            }, 25000);

            return () => clearInterval(interval);
        }        
    }, [timer]);

    const verifyUserShowSemRedeAC = () => {
        if (process.env.REACT_APP_MODE === "desktop") {
            const user = localStorage.getItem("user") !== null ? JSON.parse(localStorage.getItem("user")) : "";
            fetch(`${process.env.REACT_APP_URL}/semredeac/get/${user.id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                },
            })
                .then(response => response.json())
                .then(data => {
                    sessionStorage.setItem("showNoACDC", data.booL_SHOWREDEAC)

                })
                .catch(error => {

                });
        }
        else {
            const serial = sessionStorage.getItem("serial");

            if (!!serial) {
                fetch(`${process.env.REACT_APP_URL}/localtoweb/semredeac/get?serialNumber=${serial}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json"
                    },
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        }
                        return response.text(); // API returns a plain bool
                    })
                    .then(data => {
                        sessionStorage.setItem("showNoACDC", data.booL_SHOWREDEAC)


                    })
                    .catch(error => {

                    });
            }
        }
    }   

  useEffect(() => {
    commandsProvider.updateModemStatus(modemStatus.status);
  },[modemStatus]);
  
  return (
    <ThemeProvider theme={theme}>
      <StylesProvider generateClassName={generateClassName}>
        <Router>
          <Route exact path="/cadastro+master">
            <CreateMultiAccountuser populateData={populateData} setUserData={setUserData} setToken={setToken} />
          </Route>
          <Route exact path="/cadastro+integrador">
            <RessaleHomePage populateData={populateData} setUserData={setUserData} setToken={setToken} />
          </Route>

          <Route exact path="/">
            {process.env.REACT_APP_MODE === 'desktop'
              ? <HomePage darkMode={darkMode} theme={changeTheme} setPort={setPort} populateData={populateData} setUserData={setUserData} setToken={setToken} />
              : <WebHomePage darkMode={darkMode} theme={changeTheme} setPort={setPort} populateData={populateData} setUserData={setUserData} setToken={setToken} />
            }
          </Route>

          <Route path="/primeiro+login">
            <FirstLogin darkMode={darkMode} populateData={populateData} setUserData={setUserData} setToken={setToken} />
          </Route>

          <Route path="/cadastro+tecnico">
            <TechnicianFirstLogin darkMode={darkMode} populateData={populateData} setUserData={setUserData} setToken={setToken} />
          </Route>

          <Route path="/modo+operacao">
            <OperationMode darkMode={darkMode} />
          </Route>

          <Route path={[
            '/central', 
            '/eventos', 
            '/grupos', 
            '/registros', 
            '/amplificadores', 
            '/comando', 
            '/relatorios', 
            '/alarmes', 
            '/usuarios', 
            '/sem+permissao', 
            /*'/terminal', */
            '/dashboard',
            '/configuracoes']}>
            <Layout 
             setDarkMode={setDarkMode}
             darkMode={darkMode}
             serial={systemData.serialKeys}
             userRole={userRole}
             logout={logout}
             companyId={userData.iD_EMPRESA}
             userId={userData.id}
             user={userData.dS_NOME}
             token={token}
             rows={rows}
             setRows={setRows}
             clearState={clearState}
             generatedCommand={generatedCommand}
             clearBackdrop={clearBackdrop}
             systemData={systemData}
             modemStatus={modemStatus}
             setModemStatus={setModemStatus}
             modemConnected={modemConnected}
             setModemConnected={setModemConnected}
             timer={timer}
             setTimer={setTimer}
            >

              <Route path='/sem+permissao'>
                <UserNotAllowed userRole={userRole} />
              </Route>

              <Route path='/dashboard'>
                {
                  systemData.length === 0
                    ? null
                    : userRole !== null ? userRole.light === true || userRole.tecnico === true ? null : <Dasboards origin="client" darkMode={darkMode} rows={rows} menuItens={systemData.serialKeys} /> : null
                }
              </Route>

              <Route path='/central'>
                <Central 
                  desktopStatus={desktopStatus} 
                  newAmplifier={newAmplifier} 
                  amplifierChange={amplifierChange} 
                  serial={systemData.serialKeys}
                  serialConnected={sessionStorage.getItem("serialConnected")} 
                  darkMode={darkMode} 
                  hubConnection={hubConnectionSerial} 
                  role={userRole} 
                  rows={rows} 
                  menuItens={menuItens} 
                  systemData={systemData} 
                  port={port} 
                  modemStatus={modemStatus}
                  modemConnected={modemConnected}
                  setSystemData={setSystemData} 
                  userId={userData.id} 
                  companyId={userData.iD_EMPRESA} 
                  token={token}
                  timer={timer}
                />
              </Route>
              <Route path="/eventos">
                <ReceiveData systemData={systemData} userId={userData.id} token={token} streaming={rows} />
              </Route>
              <Route path="/alarmes">
                <Alarms systemData={systemData} userId={userData.id} token={token} streaming={rows} />
              </Route>
              <Route path="/grupos">
                <Groups groupCommands={groupCommands} role={userRole} serial={systemData.serialKeys} token={token} rows={rows} />
              </Route>
              <Route path="/registros">
                <Registers serial={systemData.serialKeys} token={token} />
              </Route>
              <Route path="/amplificadores">
                <Amplifiers 
                  user={userData} 
                  role={userRole} 
                  amplifierChange={amplifierChange} 
                  newAmplifier={newAmplifier} 
                  serial={systemData.serialKeys} 
                  token={token}
                  modemStatus={modemStatus}
                  modemConnected={modemConnected}
                  serialPortError={systemData.serialPortError}
                  desktopStatus={desktopStatus}
                  timer={timer}
                />
              </Route>
              <Route path="/comando">
                <CommandsPage></CommandsPage>
                {/* <Commands 
                  generatedCommand={generatedCommand} 
                  user={userData}
                  companyId={userData.iD_EMPRESA}
                  systemData={systemData} 
                  serial={systemData.serialKeys} 
                  token={token} 
                  streaming={rows} 
                  modemStatus={modemStatus}
                  /> */}
              </Route>
              <Route path="/relatorios">
                {userRole !== null 
                ? userRole.relatorio === true 
                ? <Relatory serial={systemData.serialKeys} user={userData} menuItens={menuItens} token={token} /> 
                : <UserNotAllowed userRole={userRole} /> 
                : null
                }
              </Route>
              <Route path="/usuarios">
                {process.env.REACT_APP_MODE === 'desktop'
                  ? userRole !== null ? userRole.usuarios === true ? <User fileUploaded={fileUploaded} setFileUploaded={setFileUploaded} setSystemData={setSystemData} systemData={systemData} token={token} user={userData} role={userRole} userId={userData.id} companyId={userData.iD_EMPRESA} serialConnected={sessionStorage.getItem("serialConnected")} hubConnection={hubConnectionSerial} /> : <UserNotAllowed userRole={userRole} /> : null
                  : userRole !== null ? userRole.usuarios === true ? <UserWeb setSystemData={setSystemData} systemData={systemData} token={token} user={userData} role={userRole} userId={userData.id} companyId={userData.iD_EMPRESA} serialConnected={sessionStorage.getItem("serialConnected")} /> : <UserNotAllowed userRole={userRole} hubConnection={hubConnectionSerial} /> : null
                }
              </Route>
              <Route path="/configuracoes">
                  <WebConfiguration />
              </Route>
              {/*<Route path="/terminal">*/}
              {/*  {userRole !== null ? userRole.usuarios === true || userRole.tecnico === true ? <TerminalFire systemData={systemData} token={token} /> : <UserNotAllowed userRole={userRole} /> : null}*/}
              {/*</Route>*/}
            </Layout>
          </Route>

          <Route path='/licencas+integrador'>
            <LayoutMaster setDarkMode={setDarkMode} darkMode={darkMode} setAppMenuItens={setAppMenuItens} userRole={userRole} clients={clients} setClients={setClients} setSystemData={setSystemData} serialKeys={serials} logout={logout} user={userData.dS_NOME} token={token} rows={rows} clearState={clearState}>
              <ResalesLicences />
            </LayoutMaster>
          </Route>

          <Route path='/painel+relatorio'>
            <LayoutMaster setDarkMode={setDarkMode} darkMode={darkMode} setAppMenuItens={setAppMenuItens} userRole={userRole} clients={clients} setClients={setClients} setSystemData={setSystemData} serialKeys={serials} logout={logout} user={userData.dS_NOME} token={token} rows={rows} clearState={clearState}>
              {token === "" ? <HomePage setUserData={setUserData} setToken={setToken} /> : <Relatory user={userData} menuItens={menuItens} token={token} />}
            </LayoutMaster>
          </Route>

          <Route path='/painel+dashboard'>
            <LayoutMaster setDarkMode={setDarkMode} darkMode={darkMode} setAppMenuItens={setAppMenuItens} userRole={userRole} clients={clients} setClients={setClients} setSystemData={setSystemData} serialKeys={serials} logout={logout} user={userData.dS_NOME} token={token} rows={rows} clearState={clearState}>
              <Dasboards origin="resale" rows={rows} menuItens={menuItens} />
            </LayoutMaster>
          </Route>

          <Route path='/usuario+integrador'>
            <LayoutMaster setDarkMode={setDarkMode} darkMode={darkMode} setAppMenuItens={setAppMenuItens} userRole={userRole} clients={clients} setClients={setClients} setSystemData={setSystemData} serialKeys={serials} logout={logout} user={userData.dS_NOME} token={token} rows={rows} clearState={clearState}>
              <ResaleUser systemData={systemData} token={token} user={userData} />
            </LayoutMaster>
          </Route>

          <Route path='/painel'>
            <LayoutMaster setDarkMode={setDarkMode} darkMode={darkMode} setAppMenuItens={setAppMenuItens} userRole={userRole} clients={clients} setClients={setClients} setSystemData={setSystemData} serialKeys={serials} logout={logout} user={userData.dS_NOME} token={token} rows={rows} clearState={clearState}>
              <Route path='/painel'>
                <EventsMaster menuItens={menuItens} clients={clients} userId={userData.id} token={token} streaming={rows} />
              </Route>
            </LayoutMaster>
          </Route>

          <Route path='/clients+panel'>
            <LayoutMaster setDarkMode={setDarkMode} darkMode={darkMode} setAppMenuItens={setAppMenuItens} userRole={userRole} clients={clients} setClients={setClients} setSystemData={setSystemData} serialKeys={serials} logout={logout} user={userData.dS_NOME} token={token} rows={rows} clearState={clearState}>
              <Route path='/clients+panel'>
                <ClientsPanel panel={ressalePanelMonitoring} clients={clients} />
              </Route>
            </LayoutMaster>
          </Route>

        </Router>
      </StylesProvider>
    </ThemeProvider>
  );
}

export default App;