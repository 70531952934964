import React, { useState, useEffect } from 'react'
import { Container, Typography, TextField, MenuItem } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
    fieldDescription: {
        width: '100%'
    }
})

export default function Description(props) {
    const classes = useStyles()
    const [description, setDescription] = useState([])
    const [currency, setCurrency] = useState("Todos")

    const handleChange = (event) => {
        setCurrency(event.target.value)
        props.setLocal(event.target.value)
    }

    const populateData = () => {
        fetch(`${process.env.REACT_APP_URL}/register/register?serialNumber=${props.serial}`, {
            method: 'GET',
            headers: { 
                "content-type": "application/json",
                "Authorization" : "Bearer "+props.token
             }
        })
        .then(response => response.json())
        .then(data => {
            setDescription(data)
            setDescription(description => [...description, {id: 0, dS_DESCRICAO: "Todos"}].reverse())
        })
    }

    useEffect(() => {
        populateData()
    }, [])

    useEffect(() => {
        if (description.length > 0)
        {
            props.setControlLoading(props.controlLoading.map(item => {
                if (item.type === "description") 
                {
                    return { ...item, status: true };
                }
                return item;
            }));
        }
    },[description]);

    return (
        <>
            <TextField
                id="outlined-select-currency"
                select
                label="ID - Descrição"
                value={currency}
                onChange={handleChange}
                className={classes.fieldDescription}
            >
                {description.map((option) => (
                    option.dS_DESCRICAO === "Todos" ?
                    <MenuItem key={option.id} value={option.dS_DESCRICAO}>
                        {option.dS_DESCRICAO}
                    </MenuItem>
                    :
                    <MenuItem key={option.id} value={`${option.dS_NUM_SERIE}-${option.dS_DESCRICAO}`}>
                        {option.dS_NUM_SERIE} - {option.dS_DESCRICAO}
                    </MenuItem>
                ))}
            </TextField>
        </>
    )
}