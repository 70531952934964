import PanelModelEnum from "../enums/PanelModeEnum";
import AlarmCommandEnum from "../enums/AlarmCommandEnum";
import Alarm from "../models/Alarm";
import Event from "../models/Event";
import PlayedMediaControl from "../models/PlayedMediaControl";
import Session from "../models/Session";
import EventCommandEnum from "../enums/EventCommandEnum";

class CommandsProvider {
	constructor() {
		this.currentAlarm = null; // Alarme atual
		this.lastAlarm = null; // Último alarme registrado
		this.panelMode = PanelModelEnum.NAO_INFORMADO; // Modo de Operação da Central
		this.modemStatus = false; // Status do Modem
		this.ACDCStatus = true; // Tem Rede ACDC?
		this.clearBackdrop = null; // Controle de fechamento do AlarmBackdrop
		this.groupAlarms = []; // Lista de alarmes por grupo
		this.groupEvents = []; // Lista de eventos por grupo
		this.subscribers = []; // Lista de funções para notificar atualizações
		this.playedMediaControl = []; // Lista de Ocorrências tocadas ou não
		this.session = this.getSession(); //Busca dados da sessão
	}

	// Atualiza dados da sessão 
	getSession() {
		if(localStorage.getItem('user'))
		{
			let session = JSON.parse(localStorage.getItem('user'))
			return new Session(session.id, session.iD_EMPRESA, session.iD_INTRANET, session.booL_MASTER, session.dS_NOME, session.email, session.senha, session.dS_CNPJ, session.dS_PANEL_SERIAL, session.role);
		}
		else
		{
			return new Session();
		}
	}

	// Retorna dados necessários para as requisições
	getSettings()
	{
		return {
			companyId: JSON.parse(localStorage.getItem('user')).iD_EMPRESA,
			token: localStorage.getItem("token"),
		}
	}

	getPanelMode()
	{
		return this.panelMode;
	}

	getACDCStatus()
	{
		return !(this.groupEvents.some((existingEvent) => existingEvent.command === EventCommandEnum.SEM_REDE_AC));
	}
  
	// Atualiza o alarme atual e mantém o último
	updateCurrentAlarmState(alarm) {
		if (!(alarm instanceof Alarm)) 
		{
			throw new Error("O objeto fornecido não é uma instância válida de Alarm.");
		}
		if (this.currentAlarm) 
		{
			this.lastAlarm = { ...this.currentAlarm };
		}
	
		this.playedMediaControl.push(new PlayedMediaControl(alarm.id));
		this.currentAlarm = alarm;
		this.notifySubscribers();
	}
  
	// Converte uma mensagem recebida em um alarme
	convertMessageToAlarm(message) {
		try
		{
			let settings = this.getSettings();

			return new Alarm(
				message.id,
				settings.companyId,
				message.fK_GRUPO,
				message.dS_LOCAL_ALARME,
				message.dS_DESCRICAO_ALARME,
				message.dS_ORIGEM_ALARME,
				this.parseDate(message.dT_EVENTO) ?? this.parseDate(message.dT_ALARME) // dT_EVENTO se definido, senão dT_ALARME
			);
		}
		catch (error)
		{
			console.log("🚀 ~ CommandsProvider ~ convertMessageToAlarm ~ error", error.message)
		}
	}

	// Adiciona eventos na lista de GroupEvents
	updateGroupEventsState(event)
	{
		if (!(event instanceof Event)) {
			throw new Error("O objeto fornecido não é uma instância válida de Event.");
		}

		this.groupEvents = this.groupEvents.filter(
			(existingEvent) => existingEvent.id !== event.id
		);

		this.playedMediaControl.push(new PlayedMediaControl(event.id));
		this.groupEvents.push(event);
		this.notifySubscribers();
	}

	// Converte uma mensagem recebida em um evento
	convertMessageToEvent(message) {
		try
		{
			let settings = this.getSettings();

			if (message.type === "alarme")
			{
				return new Alarm(message.id,settings.companyId, message.fK_GRUPO, message.dS_LOCAL_EVENTO, message.fK_OCORRENCIA, message.dS_ORIGEM_ALARME, this.parseDate(message.dT_EVENTO) ?? this.parseDate(message.dT_ALARME));
			}
			else
			{
				return new Event(message.id,message.fK_OCORRENCIA, message.dS_DESCRICAO_EVENTO, message.dS_ID_PERIFERICO, message.dS_LOCAL_EVENTO, message.dS_POS_MEM, message.fK_CENTRAL, message.fK_GRUPO, this.parseDate(message.dT_EVENTO), message.dS_ORIGEM_ALARME);
			}
		}
		catch (error)
		{
			console.log("🚀 ~ CommandsProvider ~ convertMessageToEvent ~ error:", error.message)
		}
	}

	parseDate(dateString) {

		// Verifica se a string da data está no formato ISO 8601
		const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{1,7}([+-]\d{2}:\d{2})?$/;

		if (iso8601Regex.test(dateString)) {
			const date = new Date(dateString);

			const day = String(date.getDate()).padStart(2, '0');
			const month = String(date.getMonth()).padStart(2, '0');
			const year = date.getFullYear();
			const hours = String(date.getHours()).padStart(2, '0');
			const minutes = String(date.getMinutes()).padStart(2, '0');
			const seconds = String(date.getSeconds()).padStart(2, '0');

			return new Date(year, month, day, hours, minutes, seconds);

			//return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;

		} else {
			return 'Invalid Date';
		}
	}

	// Atualiza o estado da mídia tocada
	updatePlayedMedia(playedMedia)
	{
		if (!(playedMedia instanceof PlayedMediaControl)) 
		{
			throw new Error("O objeto fornecido não é uma instância válida de PlayedMediaControl.");
		}

		// Encontre o índice do item correspondente no array
		const index = this.playedMediaControl.findIndex(item => item.id === playedMedia.id);

		if (index !== -1) {
			// Atualize o item no array
			this.playedMediaControl[index] = playedMedia;
		} else {
			// Caso o item não exista no array, você pode optar por adicioná-lo (opcional)
			this.playedMediaControl.push(playedMedia);
		}

		// Notifique os inscritos sobre a mudança
		this.notifySubscribers();
	}

	removeResetedGroups() {
		setTimeout(() => {
			// Encontra o grupo que deve ser restaurado
			const resetedGroup = this.groupAlarms.find(
				(ga) => ga.command === AlarmCommandEnum.RESTAURAR
			);
	
			if (!resetedGroup) {
				console.warn("Nenhum grupo com RESTAURAR encontrado");
				return;
			}
	
			// Remove os Events vinculados ao grupo
			this.groupEvents = this.groupEvents.filter(
				(existingEvent) => existingEvent.position !== resetedGroup.groupValue
			);
	
			// Remove os Alarms vinculados ao grupo
			this.groupAlarms = this.groupAlarms.filter(
				(existingAlarm) => existingAlarm.groupValue !== resetedGroup.groupValue
			);

			// Atualiza ClearBackdrop quando não há mais conteúdo nele
			if (this.groupAlarms.length === 0 && this.groupEvents.length === 0)
			{
				this.clearBackdrop = Math.random();
			}
	
			// Notifica os inscritos
			this.notifySubscribers();
		}, 3000);
	}

	// Processa um alarme gerado
	processAlarm(alarm) {
		if (!(alarm instanceof Alarm)) {
			throw new Error("O objeto fornecido não é uma instância válida de Alarm.");
		}

		let _isResetForSpecificGroup = false;
		
		this.groupAlarms.push(alarm);
			
		if (alarm.isDeactivateCommand()) 
		{
			if (alarm.isGeneral())
			{
				setTimeout(() => {

					if (this.groupEvents.some((existingEvent) => existingEvent.command === EventCommandEnum.SEM_REDE_AC))
					{
						//Pegar o último evento que será SEM_REDE_AC
						const lastReset = this.currentAlarm.command === AlarmCommandEnum.RESTAURAR && this.currentAlarm;
						console.log("🚀 ~ CommandsProvider ~ setTimeout ~ lastReset:", lastReset)

						if (lastReset) 
						{
							console.log('Último Reset:', lastReset.datetime);
							this.groupEvents = this.groupEvents.filter((event) => {
								console.log('Comparando:', event.datetime, lastReset.datetime);
								return event.datetime > lastReset.datetime;
							});
						}
						
						//Limpar playedMediaControl de acordo com o id do último evento. O que não for, será deletado.
						this.groupAlarms = [];
						this.currentAlarm = null;

						if (this.groupEvents.length === 0)
						{
							this.playedMediaControl = [];
							this.clearBackdrop = Math.random();
						}
					}
					else
					{
						this.groupAlarms = [];
						this.currentAlarm = null;
						this.playedMediaControl = [];
						this.groupEvents = [];
						this.clearBackdrop = Math.random();
					}

					this.notifySubscribers();
				}, 3000);
			}
			else 
			{
				_isResetForSpecificGroup = true;
			}
		}
	
		this.notifySubscribers();

		if (_isResetForSpecificGroup)
		{
			this.removeResetedGroups();
		}
	}

	// Envia o comando para o sistema
	async sendAlarm(command, group) {
		const settings = this.getSettings();

		const alarm = new Alarm(null, settings.companyId, group.position, group.local || group.position.slice(12), command, "guardiao", null);
	
		try 
		{
			const request = await fetch(`${process.env.REACT_APP_URL}/command/send`, {
				method: 'POST',
				headers: {
					"content-type": "application/json",
					"Authorization": `Bearer ${settings.token}`,
				},
				body: JSON.stringify(alarm),
			});
		
			if (!request.ok) throw new Error('Erro ao enviar comando');
			const response = await request.json();
			this.checkAlarm(response);
	
		}
		catch (error) 
		{
		  	console.error('🚀 ~ CommandsProvider ~ sendAlarm ~ error', error.message);
		}
	}
	
	checkAlarm(response) {
		console.log('Alarme recebido:', response);
		if (response?.id && response?.dS_DESCRICAO_ALARME) 
		{
			let settings = this.getSettings();
			let alarm = new Alarm(response.id, settings.companyId, response.fK_GRUPO, response.dS_LOCAL_ALARME, response.dS_DESCRICAO_ALARME, response.dS_ORIGEM_ALARME, response.dT_EVENTO ? this.parseDate(response.dT_EVENTO) : this.parseDate(response.dT_ALARME));
			this.updateCurrentAlarmState(alarm);
			this.processAlarm(alarm)
		}
		else
		{
			throw new Error("O alarme não foi registrado");
		}
	}

	updateModemStatus(status)
	{
		this.modemStatus = status;
		this.notifySubscribers();
	}
  
	updatePanelMode(message)
	{
		this.panelMode = message; //TODO - Aplicar ENUM para o Modo de Operação da Central
		this.notifySubscribers();
	}

	// Adiciona um subscriber
	subscribe(callback) {
	  	this.subscribers.push(callback);
	}
  
	// Remove um subscriber
	unsubscribe(callback) {
	  	this.subscribers = this.subscribers.filter((cb) => cb !== callback);
	}
  
	// Notifica todos os inscritos
	notifySubscribers() {
	 	this.subscribers.forEach((callback) => callback(this.getState()));
	}
  
	// Retorna o estado atual
	getState() {
		return {
			currentAlarm: this.currentAlarm,
			lastAlarm: this.lastAlarm,
			groupAlarms: this.groupAlarms,
			groupEvents: this.groupEvents,
			panelMode: this.panelMode,
			modemStatus: this.modemStatus,
			ACDCStatus : this.getACDCStatus(),
			clearBackdrop: this.clearBackdrop,
			playedMediaControl: this.playedMediaControl,
			session: this.getSession(),
		};
	}
}
  
const commandsProvider = new CommandsProvider();
export default commandsProvider;