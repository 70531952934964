import React, { useState, useEffect } from 'react'
import { Tabs, Tab, LinearProgress } from '@mui/material'
import { Box } from '@mui/system'
import EventRelatory from './EventRelatory'
import AlarmRelatory from './AlarmRelatory'
import RegisterRelatory from './RegisterRelatory'
import AmplifierRelatory from './AmplifierRelatory'
import { Typography } from '@mui/material'
import PropTypes from 'prop-types'
import AmplifierStatusRelatory from './AmplifierStatusRelatory'
import PanelStatusRelatory from './PanelStatusRelatory'
import { Height } from '@mui/icons-material'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      class="tab__item"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export default function Relatory(props) {
  const [value, setValue] = useState(0)
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  return (
    <div class="container container--min-height">
      <>
        {isLoading && 
            <LinearProgress color="secondary" style={{position: "fixed", top: "66px", left: "0", width: "100%", zIndex: "1201"}} /> 
        }
        {props.serial && props.user.role.light === false
          ?
          <Tabs TabIndicatorProps={{ sx: { display: 'none' } }} value={value} onChange={handleChange} centered
            sx={{
              '& .MuiTabs-flexContainer': {
                flexWrap: 'wrap',
              },
            }}
          >
            <Tab sx={{ fontSize: '0.7rem' }} label="Eventos" {...a11yProps(0)} />
            <Tab sx={{ fontSize: '0.7rem' }} label="Alarmes" {...a11yProps(1)} />
            <Tab sx={{ fontSize: '0.7rem' }} label="Perif&eacute;ricos" {...a11yProps(2)} />
            <Tab sx={{ fontSize: '0.7rem' }} label="Amplificadores" {...a11yProps(3)} />
            <Tab sx={{ fontSize: '0.7rem' }} label="Status Amplif." {...a11yProps(4)} />
            <Tab sx={{ fontSize: '0.7rem' }} label="Status Central" {...a11yProps(5)} />
          </Tabs>
          :
          <Tabs value={value} onChange={handleChange} centered>
            <Tab label="Eventos" {...a11yProps(0)} />
            <Tab label="Alarmes" {...a11yProps(1)} />
          </Tabs>
        }
      </>
      {props.serial
        ?
        <>
          <TabPanel value={value} index={0}>
            <EventRelatory isLoading={isLoading} setIsLoading={setIsLoading} serial={props.serial} user={props.user} menuItens={props.menuItens} token={props.token} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <AlarmRelatory isLoading={isLoading} setIsLoading={setIsLoading} serial={props.serial} user={props.user} menuItens={props.menuItens} token={props.token} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <RegisterRelatory isLoading={isLoading} setIsLoading={setIsLoading} serial={props.serial} user={props.user} token={props.token} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <AmplifierRelatory isLoading={isLoading} setIsLoading={setIsLoading} serial={props.serial} user={props.user} token={props.token} />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <AmplifierStatusRelatory isLoading={isLoading} setIsLoading={setIsLoading} serial={props.serial} user={props.user} menuItens={props.menuItens} token={props.token} />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <PanelStatusRelatory isLoading={isLoading} setIsLoading={setIsLoading} serial={props.serial} user={props.user} menuItens={props.menuItens} token={props.token} />
          </TabPanel>
        </>
        :
        <>
          <TabPanel value={value} index={0}>
            <EventRelatory isLoading={isLoading} setIsLoading={setIsLoading} serial={props.serial} user={props.user} menuItens={props.menuItens} token={props.token} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <AlarmRelatory isLoading={isLoading} setIsLoading={setIsLoading} serial={props.serial} user={props.user} menuItens={props.menuItens} token={props.token} />
          </TabPanel>
        </>
      }
    </div>
  )
}