import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';

const Modal = forwardRef(({ children, className = '', modalId = '' }, ref) => {
    const checkboxRef = useRef(null); // Ref para o input checkbox
    let header = null, body = null, footer = null;

    React.Children.forEach(children, child => {
        if (child.type === Modal.Header) {
            header = child;
        } else if (child.type === Modal.Body) {
            body = child;
        } else if (child.type === Modal.Footer) {
            footer = child;
        }
    });

    // Expondo métodos para controle externo
    useImperativeHandle(ref, () => ({
        open: () => {
            if (checkboxRef.current) {
                checkboxRef.current.checked = true;
            }
        },
        close: () => {
            if (checkboxRef.current) {
                checkboxRef.current.checked = false;
            }
        },
        isOpen: () => checkboxRef.current?.checked || false,
    }));

    return (
        <>
            {/* Checkbox controlado via label ou JS */}
            <input
                type="checkbox"
                id={modalId}
                ref={checkboxRef}
                data-control="modal"
            />
            <div className={`modal ${className}`}>
                <label htmlFor={modalId} className="modal__outarea"></label>
                { !className.includes("modal--command") ?
                <label htmlFor={modalId} className="modal__close">
                    <i className="fa fa-times fa-lg" aria-hidden="true"></i>
                </label>
                :
                <div className='modal__close modal__close--hide'></div>
                }
                {header && <div className="modal__header">{header}</div>}
                {body && <div className="modal__body">{body}</div>}
                {footer && <div className="modal__footer">{footer}</div>}
            </div>
        </>
    );
});

Modal.propTypes = {
    children: PropTypes.node.isRequired,
    modalId: PropTypes.string.isRequired,
};

// Subcomponentes
Modal.Header = ({ children }) => <>{children}</>;
Modal.Body = ({ children }) => <>{children}</>;
Modal.Footer = ({ children }) => <>{children}</>;

export default Modal;
