import React, { useState, useEffect, useCallback } from "react"
import { Container, Grid, Modal, Box, Typography, CircularProgress, Button, CardHeader, Card, CardContent, List, ListItem, ListItemText, TextField, InputLabel, OutlinedInput, IconButton, Tooltip, Tabs, Tab, LinearProgress } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import CreateUser from "../components/CreateUser"
import UserCard from "../components/UserCard"
import WebLicences from "../components/WebLicences"
import DesktopLicence from "../components/DesktopLicence"
import FreeLicence from "../components/FreeLicence"
import TechnicianLicence from "../components/TechnicianLicence"
import CompanyData from "../components/ComapnyData"
import UserLicences from "../components/UserLicences"
import TechLicence from "../components/TechLicences"
import DatabaseBackup from "../components/DatabaseBackup"
import UploadFile from "../components/UploadFile"
import EmailSettings from "../components/EmailSettings"
import { AddAPhoto, AddPhotoAlternate, Folder, Star, StarSharp, Start, SupervisedUserCircle } from "@mui/icons-material"
import LogoSelector from "../includes/LogoSelector"
import UpdateChecker from "../components/UpdateChecker"
import SemRedeACChecker from "../components/SemRedeACChecker"

import TerminalFire from '../pages/TerminalFire'



// VICTOR IMPORTS
import PropTypes from 'prop-types'
// VICTOR IMPORTS


const useStyles = makeStyles({
    pageUser: {
        minHeight: "100vh",
    },
    card: {
        padding: "3rem"
    }
})

// Victor INI
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            class="tab__item"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
// Victor FIN

export default function User(props) {
    const classes = useStyles()
    const [userList, setUserList] = useState([])
    const [update, setUpdate] = useState(0)
    const [offlineLicence, setOfflineLicence] = useState("")
    const [webUsers, setWebUsers] = useState("")
    const [openModal, setOpenModal] = useState(false)
    const [modalMessage, setModalMessage] = useState("Carregando, por favor aguarde um instante.")
    const [mainWebUser, setMainWebUser] = useState({ "id": null, "name": null, "email": null, "model": null, "position": null });
    const [webUsersList, setWebUsersList] = useState([]);
    const [value, setValue] = useState(0)
    const [isLoading, setIsLoading] = useState(false);

    //const [preData, setPreData] = useState("")
    //const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    //const [loading, setLoading] = useState(true);

    // VICTOR FUNC
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }
    // VICTOR FUNC


    const setFileUploaded = () => {
        props.setFileUploaded(true)
    }

    const handleOpenModal = (canOpen) => {
        setOpenModal(canOpen);
    }

    const updateUserEmail = (updatedUserList) => {
        var tempUserList = userList
        var newTempList = []

        tempUserList.map(item => {
            updatedUserList.filter(element => {
                if (item.id === element.model.id) {
                    element.model.email = element.email
                    newTempList.push(element.model)
                } else {
                    newTempList.push(item)
                }
            });
        });

        setUserList(Array.from(new Set(newTempList.map(item => item))))
        setUpdate(Math.random)
    }

    const removeUser = (user) => {
        var tempUserList = userList.filter(item => item.id !== user.id)
        setUserList(tempUserList)
        setUpdate(Math.random)
    }

    const addUser = (user) => {
        var tempUserList = userList
        tempUserList.push(user)
        setUserList(tempUserList)
        setUpdate(Math.random)
    }

    const backup = () => {
        fetch(`${process.env.REACT_APP_URL}/database/copy`, {
            method: 'GET',
            headers: {
                "content-type": "application/json",
                "Authorization": "Bearer " + props.token
            }
        })
    }

    const handleButtonClick = (event) => {

    }

    const getWebUsers = async () => {

        //6eaf9bb1-9a19-4b6c-b468-5202ffc217fe - ID_EMPRESA - LOCAL
        //996719d3-3b0b-4180-b82b-b44ad69f0a73 - ID_EMPRESA - WEB (OLD)
        try {
            const request = await fetch(`${process.env.REACT_APP_API_URL}/user/all?companyId=${JSON.parse(localStorage.getItem('user')).iD_EMPRESA}`, {
                method: "GET",
                headers: {
                    "content-type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("token")
                }
            });

            if (!request.ok) {
                throw new Error("Não foi possível obter a lista de usuários.");
            }

            const response = await request.json();

            setWebUsersList(response || []);
        }
        catch (error) {
            console.log("🚀 ~ getWebUsers ~ error:", error)
        }
    }

    const searchForWebMaster = () => {
        if (webUsersList.length > 0) {
            webUsersList.forEach(wu => {
                if (wu.booL_MASTER) {
                    let master = { "id": null, "name": null, "email": null, "model": null, "position": null };

                    master.id = wu.id;
                    master.name = wu.dS_NOME;
                    master.email = wu.email;
                    master.model = wu;

                    console.log("🚀 ~ searchForWebMaster ~ master:", master)

                    setMainWebUser(master);
                }
            });
        }
        else {
            throw new Error("Lista de usuários Web vazia");
        }
    }

    useEffect(() => {

    }, [update])

    useEffect(() => {
        if (userList.length > 0) {
            getWebUsers();
        }
    }, [userList])

    useEffect(() => {
        if (webUsersList && webUsersList.length > 0) {
            searchForWebMaster();
        }
    }, [webUsersList]);

    useEffect(() => {
        if (props.systemData.errorMessage !== "Nenhuma central cadastrada.Faça o upload do arquivo ROM") {
            fetch(`${process.env.REACT_APP_URL}/user/all?companyId=${props.user.iD_EMPRESA}`, {
                method: 'GET',
                headers: {
                    "content-type": "application/json",
                    "Authorization": "Bearer " + props.token
                }
            })
                .then(response => response.json())
                .then(data => setUserList(data))

            fetch(`${process.env.REACT_APP_URL}/offlinelicences/get?companyId=${props.user.iD_EMPRESA}`, {
                method: 'GET',
                headers: {
                    "content-type": "application/json",
                    "Authorization": "Bearer " + props.token
                }
            })
                .then(response => response.json())
                .then(data => { setOfflineLicence(data) })
        }
    }, [])

    //if(loading === false) return <p>Carregando dados...</p>

    return (

        //(loading) === false ? null : 

        <>
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description" s>
                <Box textAlign="center" sx={{ marginTop: "20%" }}>
                    <Typography color="#fff" id="modal-modal-title" variant="h6" component="h2">
                        {modalMessage}
                    </Typography>
                    <CircularProgress />
                </Box>
            </Modal>

            <div class="container container--min-height">
                <>
                    {isLoading &&
                        <LinearProgress color="secondary" style={{ position: "fixed", top: "66px", left: "0", width: "100%", zIndex: "1201" }} />
                    }
                    {props.user
                        ?
                        <Tabs TabIndicatorProps={{ sx: { display: 'none' } }} value={value} onChange={handleChange} centered
                            sx={{
                                '& .MuiTabs-flexContainer': {
                                    flexWrap: 'wrap',
                                },
                            }}
                        >
                            <Tab sx={{ fontSize: '0.7rem' }} label="Configurações do Sistema" {...a11yProps(0)} />
                            <Tab sx={{ fontSize: '0.7rem' }} label="Licenças Encontradas" {...a11yProps(1)} />
                            <Tab sx={{ fontSize: '0.7rem' }} label="Dados dos Usuários" {...a11yProps(2)} />
                            <Tab sx={{ fontSize: '0.7rem' }} label="Notificações por e-mail" {...a11yProps(3)} />
                            <Tab sx={{ fontSize: '0.7rem' }} label="Terminal Fire" {...a11yProps(4)} />
                        </Tabs>
                        :
                        null
                    }
                </>
                {props.user
                    ?
                    <>
                        <TabPanel value={value} index={0}>
                            <div class="container">
                               
                                {process.env.REACT_APP_MODE === 'desktop'
                                    ?
                                    props.role.upload
                                        ?
                                        <>
                                        <UploadFile setFileUploaded={setFileUploaded} systemData={props.systemData} userId={props.userId} token={props.token} companyId={props.companyId} serialConnected={props.serialConnected} setSystemData={props.setSystemData} hubConnection={props.hubConnection} />
                                            <UpdateChecker />
                                            <SemRedeACChecker />
                                        </>
                                        :
                                        null
                                    :
                                    null}

                                <LogoSelector />

                                {process.env.REACT_APP_MODE === 'desktop'
                                    ?
                                    <DatabaseBackup onButtonClick={handleButtonClick} />
                                    :
                                    null}
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <div class="container">
                                <div class="mb-5">
                            {offlineLicence !== "nenhuma licença encontrada" || offlineLicence !== "undefined" || offlineLicence !== ""
                        ?
                            props.user.role.tecnico === true 
                            ? 
                                <TechLicence licence={props.systemData.licenceKey} token={props.token} /> 
                            : 
                                <UserLicences setWebUsers={setWebUsers} serialKeys={props.systemData.serialKeys} token={props.token} />
                        : 
                            null}
                            </div>
                                {props.user.role.light == true
                                    ?
                                    <>
                                        <div class="mb-5">
                                            <>
                                                {offlineLicence !== "nenhuma licença encontrada" || offlineLicence !== "undefined" || offlineLicence !== ""
                                                    ?
                                                    props.systemData.licenceKey != null && props.systemData.licenceKey != ""
                                                        ?
                                                        props.systemData.licenceKey.lenght > 1
                                                            ?
                                                            props.systemData.licenceKey.split("-")[1][props.systemData.licenceKey.split("-")[0][1]] === "6"
                                                                ?
                                                                <DesktopLicence actionType="active" setUpdate={setUpdate} systemData={props.systemData} user={props.user} handleOpenModal={handleOpenModal} />
                                                                //<div>null1</div>
                                                                :
                                                                <DesktopLicence actionType="renew" setUpdate={setUpdate} systemData={props.systemData} user={props.user} handleOpenModal={handleOpenModal} />
                                                            //<div>null2</div>
                                                            :
                                                            <DesktopLicence actionType="active" setUpdate={setUpdate} systemData={props.systemData} user={props.user} handleOpenModal={handleOpenModal} />
                                                        //<div>null3</div>
                                                        :
                                                        <FreeLicence token={props.token} hubConnection={props.hubConnection} systemData={props.systemData} serialConnected={props.serialConnected} companyId={props.companyId} setUpdate={setUpdate} fileUploaded={props.fileUploaded} user={props.user} setSystemData={props.setSystemData} />
                                                    :
                                                    <FreeLicence token={props.token} hubConnection={props.hubConnection} systemData={props.systemData} serialConnected={props.serialConnected} companyId={props.companyId} setUpdate={setUpdate} fileUploaded={props.fileUploaded} user={props.user} setSystemData={props.setSystemData} />
                                                }
                                            </>
                                        </div>
                                    </>
                                    :
                                    props.user.role.tecnico === true
                                        ?
                                        <>
                                            <div class="mb-5">


                                                <TechnicianLicence systemData={props.systemData} user={props.user} />
                                                <Grid container spacing={3}>
                                                    {userList.map(user => (
                                                        <Grid key={user.id} item xs={12} md={12} lg={4}>
                                                            <UserCard removeUser={removeUser} key={user.id} user={user} />
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div class="mb-5">
                                                <WebLicences
                                                    webUsers={webUsers}
                                                    webUsersList={webUsersList}
                                                    setUpdate={setUpdate}
                                                    systemData={props.systemData}
                                                    userList={userList}
                                                    user={props.user}
                                                    handleOpenModal={handleOpenModal}
                                                    updateUserEmail={updateUserEmail}
                                                    listSavedEmails={userList.filter(user => user.email != null)}
                                                />
                                                <DesktopLicence actionType="renew" setUpdate={setUpdate} systemData={props.systemData} user={props.user} handleOpenModal={handleOpenModal} />
                                            </div>

                                        </>
                                }
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <div class="container">
                                {props.user.role.light == true
                                    ?
                                    <>

                                    </>
                                    :
                                    props.user.role.tecnico === true
                                        ?
                                        <>
                                            <div class="mb-5">                                                
                                                <TechnicianLicence systemData={props.systemData} user={props.user} />
                                                <Grid container spacing={3}>
                                                    {userList.map(user => (
                                                        <Grid key={user.id} item xs={12} md={12} lg={4}>
                                                            <UserCard removeUser={removeUser} key={user.id} user={user} />
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </div>
                                        </>
                                        :
                                        <>

                                            {!!mainWebUser.id &&
                                                <Card sx={{ marginBottom: 5 }}>
                                                    <CardHeader avatar={<StarSharp color="primary" />} title="Usuário Web - Master" />
                                                    <CardContent>
                                                        <List>
                                                            <ListItem>
                                                                <TextField sx={{ minWidth: "100%" }} id="web-user-name" variant="standard" label="Nome" defaultValue={mainWebUser.name} disabled={true} />
                                                            </ListItem>
                                                            <ListItem>
                                                                <TextField sx={{ minWidth: "100%" }} id="web-user-email" variant="standard" label="E-mail" defaultValue={mainWebUser.email} disabled={true} />
                                                            </ListItem>
                                                        </List>
                                                    </CardContent>
                                                </Card>
                                            }
                                            <CreateUser addUser={addUser} user={props.user} />
                                            <Grid container spacing={3}>
                                                {userList.map(user => (
                                                    <Grid key={user.id} item xs={12} md={12} lg={4}>
                                                        <UserCard removeUser={removeUser} key={user.id} user={user} webMaster={user.id === mainWebUser.id} />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                            <CompanyData user={props.user} />

                                        </>
                                }
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            <div class="container">
                                    <EmailSettings />
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={4}>
                            <div class="container">
                                <TerminalFire systemData={props.systemData} token={props.token} />
                            </div>
                        </TabPanel>
                    </>
                    :
                    null
                }
            </div>

        </>
    )
}