import React, { useEffect } from 'react'
import { Typography, Container } from '@mui/material'
//import Card from '@mui/material/Card'
//import CardContent from '@mui/material/CardContent'
import makeStyles from "@mui/styles/makeStyles"
import SystemData from '../components/SystemData'

const useStyles = makeStyles({
    pageCentral: {
        minHeight: "100vh",
    },
    cardCentral: {
        padding: 0,
        width: '96%', //50vw
        margin: 'auto',
        marginTop: '10px',
        marginBottom: 20
    }
})

export default function Central(props) {
    const classes = useStyles()

    return (
        <div class="container container--min-height d-flex d-flex--center">
            {props.systemData.errorMessage ?
                <Typography variant="body2" style={{textAlign: "center"}} color="error">{props.systemData.errorMessage}</Typography> :
                <>
                    <center class="mb-5">
                        {/* <label><h4>Dados do Sistema</h4></label> */}
                    </center>

                    <SystemData 
                        desktopStatus={props.desktopStatus} 
                        newAmplifier={props.newAmplifier} 
                        rows={props.rows} 
                        systemData={props.systemData} 
                        modemStatus={props.modemStatus}
                        modemConnected={props.modemConnected}
                        panel={props.panel} 
                        amplifierChange={props.amplifierChange} 
                        darkMode={props.darkMode} 
                        token={props.token}
                        timer={props.timer}
                    />

                </>
            }
        </div>
    )
}