import React, { useState, useEffect } from "react"
import AlarmCommandEnum from "../enums/AlarmCommandEnum"
import groupsProvider from "../providers/GroupsProvider";
import AlarmAudio from '../assets/Alarm.mp3'

export default function AlarmCard(props) {
    // const classes = useStyles()
    const [group, setGroup] = useState(groupsProvider.getGroupByPosition(props.row.groupValue));

    const bgColor = () => {
        if (props.row.command === AlarmCommandEnum.RESTAURAR) {
            return "bg-color-card-green"
        }
        if (props.row.command === AlarmCommandEnum.BRIGADA) {
            return "bg-color-card-orange"
        }
        if (props.row.command === AlarmCommandEnum.SILENCIAR) {
            return "bg-color-card-yellow"
        }
        if (props.row.command === AlarmCommandEnum.ABANDONO) {
            return "bg-color-card-red"
        }
    }
    const ForeColor = () => {
        if (props.row.command === AlarmCommandEnum.RESTAURAR) {
            return "color-card-green"
        }
        if (props.row.command === AlarmCommandEnum.BRIGADA) {
            return "color-card-orange"
        }
        if (props.row.command === AlarmCommandEnum.SILENCIAR) {
            return "color-card-yellow"
        }
        if (props.row.command === AlarmCommandEnum.ABANDONO) {
            return "color-card-red"
        }
    }

    const CardIcon = () => {
        if (props.row.command === AlarmCommandEnum.RESTAURAR) {
            return "df-alarm-reset";
        }
        if (props.row.command === AlarmCommandEnum.BRIGADA) {
            return "df-alarm-brigade";
        }
        if (props.row.command === AlarmCommandEnum.SILENCIAR) {
            return "df-alarm-visual";
        }
        if (props.row.command === AlarmCommandEnum.ABANDONO) {
            return "df-alarm-abandon";
        }
    }

    return (
        <div class="info-box info-card info-box--full h-100 mx-auto">
            <div className={`info-box__header info-card__header ${bgColor()}`} >
                { group.position?.slice(12) === "0" &&
                    <span><strong>{ group.local }</strong></span>
                }
                { group.position?.slice(12) !== "0" &&
                    <span><strong>GRUPO {group.position.slice(12)}{ !!group.local && ` - ${group.local}`}</strong></span>
                }
            </div>
            <div class="info-box__body" >
                    <h1 class="h2 text-center">{
                        props.row.command === AlarmCommandEnum.SILENCIAR ? "SILENCIAR ALARME" : null ||
                            props.row.command === AlarmCommandEnum.ABANDONO ? "ALARME DE ABANDONO" : null ||
                                props.row.command === AlarmCommandEnum.BRIGADA ? "ALARME DE BRIGADA" : null ||
                                    props.row.command === AlarmCommandEnum.RESTAURAR ? "SISTEMA RESTAURADO" : null
                    }
                    </h1>
                    <i class={`df ${CardIcon()} df-xxl ${ForeColor()}`}></i>
                    <h3>
                        Origem: {props.row.origin}
                    </h3>
            </div>
            <div class="info-box__footer" >
            <div textAlign="center">
                    <label>
                        {new Date(props.row.datetime).toLocaleString().slice(0, 20).replace(/-/g, "/").replace("T", " ").replace(",", "")}
                    </label>
                </div>
            </div>
        </div>
    )
}