import React, { useState, useEffect } from "react"
import { Grid, Box, Button, Tooltip, IconButton } from '@mui/material'
import { Stack, Paper, styled, Typography, Autocomplete, TextField, InputAdornment, LinearProgress, Container } from '@mui/material'
import makeStyles from "@mui/styles/makeStyles"
import RegisterItem from "../components/RegisterItem"
import { MoveToInbox, Search } from '@mui/icons-material'

const useStyles = makeStyles({
    pageRegister: {
        minHeight: "100vh"
    }
})

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    width: '100%',
}));

const ItemHeader = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.text.secondary,
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: '#fff',
    width: '100%',
    minHeight: '30px'
}));

export default function Registers(props) {
    const classes = useStyles()

    const [serialNumber, setSerial] = useState(props.serial)
    const [registers, setRegisters] = useState([])
    const [registerList, setRegisterList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [autocompleteList, setAutocompletelist] = useState([])

    const [register, createDll] = useState([])

    const autocomplete = (event) => 
        {
        if (event.target.innerText === undefined || event.target.innerText === "") {
            setRegisterList(registers)
        } else {
            var autocomplete = registers.find(item => item.dS_NUM_SERIE === event.target.innerText.split(" - ")[1])
            setRegisterList(Array(autocomplete))
        }
    }
    const populateData = () => {
        setIsLoading(true);

        fetch(`${process.env.REACT_APP_URL}/register/all`, {
            method: 'POST',
            headers: {
                "content-type": "application/json",
                "Authorization": "Bearer " + props.token
            },
            body: JSON.stringify({ serialNumber })
        })
        .then(res => res.json())
        .then(data => {
            setRegisterList(data.sort((a, b) => parseInt(a.vL_MEM) - parseInt(b.vL_MEM)))
            setRegisters(data.sort((a, b) => parseInt(a.vL_MEM) - parseInt(b.vL_MEM)))
            setAutocompletelist(data.map(item => {
                return {
                    label: item.vL_MEM + " - " + item.dS_NUM_SERIE + " - " + item.dS_DESCRICAO + " - " + item.fK_GRUPO.slice(12) + " - " + item.dS_LOCALIZACAO
                }
            }
            ))
            setIsLoading(false);
        });
    }

    useEffect(() => {
        populateData();
    }, [])

    const header = {
        id: 1,
        vL_MEM: "Pos. Memória",
        dS_NUM_SERIE: "I.D.",
        dS_DESCRICAO: "Descrição",
        fK_GRUPO: "000000000000Grupo",
        dS_LOCALIZACAO: "Localização"
    }

    const createDllButton = () => {
        setIsLoading(true);

        fetch(`${process.env.REACT_APP_URL}/register/dll?serial=${serialNumber}`,
        {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Expose-Headers': '*',
                "Authorization": "Bearer " + props.token
            }
        })
        .then(response => response.blob())
        .then(blob => {
            const url = window.URL.createObjectURL(
                new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                'Config.dll',
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
        })
        .then(setIsLoading(false));
    }

    return (
        <main class="container container--min-height">
            {isLoading && 
                <LinearProgress color="secondary" style={{position: "fixed", top: "66px", left: "0", width: "100%", zIndex: "1201"}} /> 
            }

            <center>
                <label><h3>Equipamentos Cadastrados</h3></label>
            </center>

            {process.env.REACT_APP_MODE === 'desktop' ?
                <Box>
                    <Tooltip title="Salvar .DLL">
                        <IconButton onClick={() => createDllButton()}>
                            <MoveToInbox />
                        </IconButton>
                    </Tooltip>
                </Box> : null}


            <Grid container spacing={1} textAlign='center' sx={{ marginTop: "5px" }} >
                {/* sx = paddingRight: "10vw", paddingLeft: "10vw", paddingBottom: "2vh" */}
                <Grid item xs={{ maxWidth: "100%" }} md={12} lg={12} width={"100%"}  >
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={autocompleteList}
                        onChange={(event) => autocomplete(event)}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                placeholder="Pesquisar"
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (<InputAdornment><Search color="secondary" /></InputAdornment>)
                                }}
                            />
                        }
                    />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <Typography sx={{ padding: '1rem', marginLeft: 0 }} variant="body1" color="textPrimary">Equipamentos Encontrados: {registers.length} </Typography>
                </Grid>
            </Grid>

            <Stack sx={{ maxWidth: "100%" }} alignItems="center" spacing={2}>
                <ItemHeader item key={header.id} xs={12} md={6} lg={4}>
                    <RegisterItem register={header} />
                </ItemHeader>
                {registerList.map(register => (
                    <Item item key={register.id} xs={12} md={6} lg={4}>
                        <RegisterItem register={register} />
                    </Item>
                ))}
            </Stack>
        </main>
    )
}