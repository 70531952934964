import { useState, useEffect } from 'react'
import { Container, Box, Typography, Card, CardHeader, CardContent, Grid, Button, Snackbar } from '@mui/material'
import { Computer } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles"
import TerminalFireLicence from '../components/TerminalFireLicence'

const useStyles = makeStyles({
    pageTerminal: {
        minHeight: "100vh",
    },
    terminalKey: {
        width: "20rem",
        marginBottom: "3rem"
    },
    terminalCard: {
        padding: "1rem",
        marginTop: "1rem",
        marginBottom: "1rem"
    },
})

export default function TerminalFire(props) {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const [computerList, setComputerList] = useState([])
    const [message, setMessage] = useState("")
    const [active, setActive] = useState(0)

    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL}/terminal/get`, {
            method: "GET",
            headers: { "content-type": "application/json" },
        }).then(response => response.json())
            .then(data => {
                setComputerList(data)
            })

    }, [active])

    const handleChange = (id) => {
        var tempPcList = [...computerList]
        tempPcList.find(item => item.id === id).enabled = false
        tempPcList.find(item => item.id === id).name = null
        tempPcList.find(item => item.id === id).ip = null
        setComputerList(tempPcList)

        fetch(`${process.env.REACT_APP_URL}/terminal/remove?id=${id}`, {
            method: "GET",
            headers: { "content-type": "application/json" },
        })
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <main class="container container--min-height">
            
            <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
                message={message}/>

            <TerminalFireLicence setActive={setActive} systemData={props.systemData} token={props.token} />

            <Card className={classes.terminalCard}>
                <CardHeader
                    avatar={<Computer color="primary" />}
                    title="Computadores Registrados"/>
                {computerList.length > 0
                    ?
                    <CardContent sx={{ px: '3rem' }}>
                        {/* <Typography variant="h6">Licença terminal Fire para {licenceLenght} computadores.</Typography> */}
                        <Typography variant="body1">Para desvincular um computador da licença clique no botão "Desvincular".</Typography>
                        <Typography variant="body1">A vaga liberada será ocupada automaticamente pelo próximo computador que se conectar ao Guardião 2.0!</Typography>
                        <Grid sx={{ mt: '10px' }} container spacing={3}>
                            {computerList.map(item => {
                                return <Grid item xs={12} md={12} lg={12}>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                                        <Button
                                            variant="contained"
                                            color="error"
                                            onClick={() => handleChange(item.id)}
                                            disabled={!item.enabled}
                                        >Desvincular</Button>
                                        <Typography alignItems="center">{item.name === null ? "Disponível" : item.name}</Typography>
                                        <Typography sx={{ width: "200px" }}>HD{item.ip === null ? " - " : item.ip}</Typography>
                                    </Box>
                                </Grid>
                            })}
                        </Grid>
                    </CardContent>
                    : null}
            </Card>
        </main>
    )
}