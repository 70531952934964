import React, { useState, useEffect } from "react"
import { Grid } from '@mui/material'
import { Container } from '@mui/material'
import GroupCard from "../components/GroupCard"
import makeStyles from "@mui/styles/makeStyles"
import LinearProgress from '@mui/material/LinearProgress'
import groupsProvider from "../providers/GroupsProvider"
import Group from "../models/Group"

const useStyles = makeStyles({
    pageGroup: {
        minHeight: "100vh"
    }
})

export default function Groups(props) {
    const classes = useStyles()
    const [groups, setGroups] = useState([])
    var [groupCounter, setGroupCounter] = useState(1)
    const [isLoading, setIsLoading] = useState(true)
    const [groupCommands, setGroupCommands] = useState([])

    const handleUpdate = async (group) => {
        await fetch(`${process.env.REACT_APP_URL}/group/update/location`, {
            method: 'POST',
            headers: {
                "content-type": "application/json",
                "Authorization": "Bearer " + props.token
            },
            body: JSON.stringify({
                "value": group.vL_CADASTRO,
                "local": group.dS_LOCALIZACAO
            })
        });
        
        let groupInstances = groups.map(groupData => new Group(groupData));

        groupInstances.forEach(newGroup => {
            if (newGroup.position === group.vL_CADASTRO) {
                newGroup.local = group.dS_LOCALIZACAO; // Atualiza apenas a propriedade local
            }
        });

        groupsProvider.updateGroupsList(groupInstances);
    }

    const orderGroups = (a, b) => {    
        if (parseInt(a.vL_CADASTRO.charAt(12)) < parseInt(b.vL_CADASTRO.charAt(12)))
        {
            return -1;
        }
        if (parseInt(a.vL_CADASTRO.charAt(12)) > parseInt(b.vL_CADASTRO.charAt(12))) 
        {
            return 1;
        }
        // a deve ser igual a b
        return 0;
    }

    const getRegistersBySerial = async (serialNumber) => {
        try 
        {
            const response = await fetch(`${process.env.REACT_APP_URL}/register/all`, {
                method: 'POST',
                headers: {
                    "content-type": "application/json",
                    "Authorization": "Bearer " + props.token
                },
                body: JSON.stringify({ serialNumber })
            });

            return await response.json();
        }
        catch (e)
        {
            console.log("🚀 ~ getRegistersBySerial ~ e:", e)       
        }
    }

    const getGroupsData = async () => {
        try
        {
            const response = await fetch(`${process.env.REACT_APP_URL}/group/all`, {
                method: 'POST',
                headers: {
                    "content-type": "application/json",
                    "Authorization": "Bearer " + props.token
                },
                body: JSON.stringify({ "serialNumber": props.serial })
            });

            if (!response.ok)
            {
                throw new Error("Não foi possível obter os dados de grupos");
            }

            const res = await response.json();

            if (!!res)
            {
                if (!!sessionStorage.getItem('amplifiers'))
                {
                    setTimeout(async () => {
                        let workData = res;
                        let controlAmplifiers = JSON.parse(sessionStorage.getItem('amplifiers'));
                        let controlRegisters = await getRegistersBySerial(res[0].fK_CENTRAL_GRUPO);
                        
                        workData.forEach(group => {
                            group.amplifier = controlAmplifiers.all.filter(a => a.fK_VL_CADASTR_GRUPO === group.vL_CADASTRO);
                            group.register.forEach(r => {
                                controlRegisters.forEach(control => {
                                    if (r.dS_NUM_SERIE === control.dS_NUM_SERIE)
                                    {
                                        r.dS_LOCALIZACAO_EXT = control.dS_LOCALIZACAO;
                                    }
                                });
                            });
                        });
    
                        setGroups(workData.sort(orderGroups));
                        setIsLoading(false);
                    }, 1000);
                }
                else
                {
                    console.log("🚀 ~ getGroupsData ~ e", "Não foi possível obter os dados de amplificadores, nova tentativa!");
                    getGroupsData();
                }
            }
        }
        catch (e)
        {
            console.log("🚀 ~ getGroupsData ~ e:", e)
        }
        finally
        {
        }
    }

    useEffect(() => {
        getGroupsData();
    }, []);

    useEffect(() => {
        console.log("🚀 ~ useEffect ~ props.groupCommands:", props.groupCommands)
        setGroupCommands(props.groupCommands)
        console.log("useEffect ~ command", groupCommands.find(item => item.groupValue === groups[1].vL_CADASTRO) || groupCommands.find(item => item.groupValue === 'general'));
    }, [props.groupCommands])

    return (
        <main class="container container--min-height">

            <center class="mb-5">
                <label><h3>Grupos Registrados no Sistema</h3></label>
            </center>

            {isLoading && 
                <LinearProgress color="secondary" style={{position: "fixed", top: "66px", left: "0", width: "100%"}} /> 
            }
            <div className="grid" data-grid-xl-columns="3" data-grid-md-columns="2" data-grid-xs-columns="1">
            {groups.map((group,index) => (
                <div key={group.vL_CADASTRO} class="grid__item grid__item--no-grow mb-5" >
                    <GroupCard 
                        command={groupCommands.find(item => item.groupValue === group.vL_CADASTRO) || groupCommands.find(item => item.groupValue === 'general')} 
                        rows={props.rows} 
                        role={props.role} 
                        token={props.token} 
                        group={group} 
                        groupIndex={index}
                        handleUpdate={handleUpdate} 
                        length={groups.length} 
                        counter={groupCounter++} 
                    />
                </div>
            ))}
            </div>
        </main>
    )
}