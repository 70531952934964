const EventCommandEnum = Object.freeze({
    TEMPERATURA_BAIXA: "TEMPERATURA BAIXA",
    TEMPERATURA_ALTA: "TEMPERATURA ALTA",
    SISTEMA_RESTAURADO: "SISTEMA RESTAURADO",
    SINALIZACAO_VISUAL_ATIVADA: "SINALIZAÇÃO VISUAL ATIVADA",
    SILENCIAR_ALARME: "SILENCIAR ALARME",
    SEM_REDE_AC: "SEM REDE AC",
    REMOVIDO: "REMOVIDO",
    REDE_AC_INADEQUADA: "REDE AC INADEQUADA",
    MONITOR_DE_PRESSAO: "MONITOR DE PRESSÃO",
    MONITOR_DE_FLUXO: "MONITOR DE FLUXO",
    MONITOR_DE_ENERGIA: "MONITOR DE ENERGIA",
    MONITOR_DE_ACESSO: "MONITOR DE ACESSO",
    LINEAR_COM_FALHA: "LINEAR COM FALHA",
    FORA_DE_OPERACAO: "FORA DE OPERAÇÃO",
    FALHA: "FALHA",
    EQUIPAMENTO_DESATIVADO: "EQUIPAMENTO DESATIVADO",
    EQUIPAMENTO_ATIVADO: "EQUIPAMENTO ATIVADO",
    EM_OPERACAO: "EM OPERAÇÃO",
    DISPARO_POR_TEMPERATURA: "DISPARO POR TEMPERATURA",
    DISPARO_POR_GAS: "DISPARO POR GÁS",
    DISPARO_POR_FUMACA: "DISPARO POR FUMAÇA",
    DISPARO_POR_CHAMA: "DISPARO POR CHAMA",
    DISPARO_MANUAL: "DISPARO MANUAL",
    CONTROLE_DE_TEMPERATURA: "CONTROLE DE TEMPERATURA",
    CONTROLE_AMBIENTAL: "CONTROLE AMBIENTAL",
    BATERIA_BAIXA: "BATERIA BAIXA",
    AVISO_DE_BRIGADA: "AVISO DE BRIGADA",
    AMONIA: "AMÔNIA",
    ALERTA_MOVEL: "ALERTA MÓVEL",
    ALARME_VISUAL: "ALARME VISUAL",
    ALARME_EXTRA: "ALARME EXTRA",
    ALARME_DE_RETORNO: "ALARME DE RETORNO",
    ALARME_DE_BRIGADA: "ALARME DE BRIGADA",
    ALARME_DE_ABANDONO_LIGADO: "ALARME DE ABANDONO LIGADO",
    ALARME_DE_ABANDONO: "ALARME DE ABANDONO",
    ACIONADOR_DE_HIDRANTE: "ACIONADOR DE HIDRANTE",
    ACIONADO: "ACIONADO"
});

export default EventCommandEnum;