import { useState, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { Router } from '@mui/icons-material'
import Snackbar from '@mui/material/Snackbar';
import InfoBox from '../includes/InfoBox'
import PropTypes from 'prop-types';
import FormControl from '../includes/FormControl'
import Helper from '../providers/Helpers';

const AmplifierCard = (props) => {
    // State
    const [amplifier, setAmplifier] = useState(props.amplifier);
    const [openToast, setOpenToast] = useState();
    const [lockEdit, setLockEdit] = useState(false);
    
    // View Controllers
    const [edit, setEdit] = useState(false);
    const [location, setLocation] = useState("1 - sem nome");

    // Input Values
    // const [amplifierName, setAmplifierName] = useState(amplifier.dS_NOME || '');
    // const [selectedGroup, setSelectedGroup] = useState(amplifier.fK_VL_CADASTR_GRUPO || '');
    const [amplifierName, setAmplifierName] = useState();
    const [selectedGroup, setSelectedGroup] = useState();

    const enableEdit = () => {
        if (edit)
        {
            sessionStorage.setItem('isEditing',true);
        }
        else 
        {
            sessionStorage.removeItem('isEditing');
        }
        setEdit(!edit)
    }

    //delete
    const deleteAmplifierData = async function (radioId) {
        try
        {
            const response = await fetch(`${process.env.REACT_APP_URL}/amplifier/delete?ds_dl=${radioId}`, {
                method: "DELETE",
                headers: {
                    "content-type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("token")
                }
            });

            if (!response.ok)
            {
                throw Error(JSON.stringify({type: "server", message: "Houve um erro no processo de exclusão!"}));
            }

            const res = await response.json();

            if (res.message === true) 
            {
                await integrityDataCheck(amplifier, "delete");
            }
            else
            {
                throw Error(JSON.stringify({type: "client", message: "Não foi possível atualizar a tela!"}));
            }
        }
        catch (error)
        {
            await integrityDataCheck(amplifier, "delete");
            
            let response = Helper.TryParseJson(error);

            if (response)
            {
                console.log("🚀 ~ deleteAmplifier ~ error:", response.message);
                window.alert(response.message);
            }
            else
            {
                console.log("🚀 ~ deleteAmplifier ~ error:", error);
            }
        }
    }

    //update group
    const updateAmplifierGroup = async (group, amplifier) => {
        try
        {
            const response = await fetch(`${process.env.REACT_APP_URL}/amplifier/update/group`, {
                method: 'POST',
                headers: {
                    "content-type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify({
                    group: group,
                    amplifier: amplifier
                })
            });
    
            if (!response.ok)
            {
                throw Error(JSON.stringify({type: "server", message: `Erro ao alterar o GRUPO do Amplificador ${amplifier.dS_DL}!`}));
            }
            else
            {
                setLocation(group.dS_LOCALIZACAO === "null" ? group.vL_CADASTRO.slice(12) + " - Sem nome registrado" : `${group.vL_CADASTRO.slice(12)} - ${amplifier.dS_LOCALIZACAO}`)
                setSelectedGroup(amplifier.fK_VL_CADASTR_GRUPO);
                setAmplifier(amplifier);
            }

            return response;
        }
        catch (error)
        {
            let response = Helper.TryParseJson(error);

            if (response)
            {
                console.log("🚀 ~ updateAmplifierGroup ~ error:", response.message);
                window.alert(response.message);
            }
            else
            {
                console.log("🚀 ~ updateAmplifierGroup ~ error:", error);
            }
        }
    }

    //update name
    const updateAmplifierName = async (amplifier) => {
        try 
        {
            const response = await fetch(`${process.env.REACT_APP_URL}/amplifier/name`, {
                method: 'POST',
                headers: {
                    "content-type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify({
                    'dS_STATUS': amplifier.dS_STATUS,
                    'dS_DH': amplifier.dS_DH,
                    'dS_DL': amplifier.dS_DL,
                    'dS_LOCALIZACAO': amplifier.dS_LOCALIZACAO,
                    'dS_MY': amplifier.dS_MY,
                    'dS_NOME': amplifier.dS_NOME,
                    'dS_NUMSERIE': amplifier.dS_NUMSERIE,
                    'dT_AMPLIFICADOR': amplifier.dT_AMPLIFICADOR,
                    'fK_CENTRAL_AMPLIFICADOR': amplifier.fK_CENTRAL_AMPLIFICADOR,
                    'fK_VL_CADASTR_GRUPO': amplifier.fK_VL_CADASTR_GRUPO,
                    'id': amplifier.id,
                    'vL_BATERIA': amplifier.vL_BATERIA,
                    'vL_FONTE': amplifier.vL_FONTE,
                    'vL_SINAL': amplifier.vL_SINAL
                })
            });

            if (!response.ok)
            {
                throw Error(JSON.stringify({type: "server", message: `Erro ao alterar o NOME do Amplificador ${amplifier.dS_DL}!`}));
            }
            else
            {
                setAmplifier(amplifier);
            }

            return response;
        }
        catch (error)
        {
            let response = Helper.TryParseJson(error);

            if (response)
            {
                console.log("🚀 ~ updateAmplifierName ~ error:", response.message)
                window.alert(response.message);
            }
            else
            {
                console.log("🚀 ~ updateAmplifierName ~ error:", error);
            }  
        }
    }

    //update data control
    const updateAmplifierData = async function (radioId) {   
        try 
        {
            let groupToUpdate = {};
            let amplifierToUpdate = amplifier;

            let data = {
                name: document.querySelector(`#amplifier-name-${radioId}`).value,
                group: document.querySelector(`#amplifier-group-${radioId}`).value,
            }
            
            if (!!data.name || !!data.group)
            {
                amplifierToUpdate.dS_NOME = data.name;
                
                groupToUpdate = props.groupList.find(g => {
                    return g.vL_CADASTRO === data.group ? g : null
                });

                if (!!groupToUpdate)
                {
                    amplifierToUpdate.dS_LOCALIZACAO = groupToUpdate.dS_LOCALIZACAO;
                    amplifierToUpdate.fK_VL_CADASTR_GRUPO = groupToUpdate.vL_CADASTRO;
                }

                if (data.name === "")
                {
                    data.name = null;
                }
                
                let step1 = await updateAmplifierName(amplifierToUpdate);
                let step2 = await updateAmplifierGroup(groupToUpdate, amplifierToUpdate);
                
                if (!!step1.ok && !!step2.ok)
                {
                    await integrityDataCheck(amplifierToUpdate, 'update');
                }
            }
            else
            {
                throw new Error(JSON.stringify({type: "validation", message: "Não foi possível salvar!\n\nVerifique os campos e tente novamente!"}));
            }
        }
        catch (error)
        {
            let response = Helper.TryParseJson(error);

            if (response)
            {
                console.log("🚀 ~ updateAmplifierData ~ error:", response.message);
                window.alert(response.message);
            }
            else
            {
                console.log("🚀 ~ updateAmplifierData ~ error:", error);
            }
        }
    }

    //synchronize
    const synchronizeAmplifierData = async function (radioId) {
        try
        {
            const response = await fetch(`${process.env.REACT_APP_URL}/amplifier/synchronize`, {
                method: 'POST',
                headers: {
                    "content-type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify({
                    'dS_STATUS': amplifier.dS_STATUS,
                    'dS_DH': amplifier.dS_DH,
                    'dS_DL': amplifier.dS_DL,
                    'dS_LOCALIZACAO': amplifier.dS_LOCALIZACAO,
                    'dS_MY': amplifier.dS_MY,
                    'dS_NOME': amplifier.dS_NOME,
                    'dS_NUMSERIE': amplifier.dS_NUMSERIE,
                    'dT_AMPLIFICADOR': amplifier.dT_AMPLIFICADOR,
                    'fK_CENTRAL_AMPLIFICADOR': amplifier.fK_CENTRAL_AMPLIFICADOR,
                    'fK_VL_CADASTR_GRUPO': amplifier.fK_VL_CADASTR_GRUPO,
                    'id': amplifier.id,
                    'vL_BATERIA': amplifier.vL_BATERIA,
                    'vL_FONTE': amplifier.vL_FONTE,
                    'vL_SINAL': amplifier.vL_SINAL
                })
            });

            if (!response.ok)
            {
                throw Error(JSON.stringify({type: "server", message: `Não foi possível sincronizar o amplificador ${radioId}`}));
            }
            else
            {   
                const updatedAmplifier = await response.json();

                await integrityDataCheck(updatedAmplifier, 'synchronize');
            }
            
        }
        catch (error)
        {
            let response = Helper.TryParseJson(error);

            if(response)
            {
                console.log("🚀 ~ synchronizeAmplifierData ~ error:", response.message)

                if (JSON.parse(error).type === "server")
                {
                    window.alert(response.message);
                }
            }
            else
            {
                console.log("🚀 ~ synchronizeAmplifierData ~ error:", error)
            }
        }
    }

    const integrityDataCheck = async function (dataToCheck, action = "", time = 5000) {

        /*document.querySelector('[data-action="refresh-system"]').click();*/
        document.querySelector('.hidden[data-action="refresh-system"]').click();

        setTimeout(() => {
            let dataControl = JSON.parse(sessionStorage.getItem("amplifiers"));
            let integrityCheck = dataControl.all.filter((a) => a.dS_DL === dataToCheck.dS_DL)[0];

            switch (action)
            {
                case "update" || "synchronize":
                    if (integrityCheck.dS_NOME === dataToCheck.dS_NOME && dataToCheck.fK_VL_CADASTR_GRUPO === integrityCheck.fK_VL_CADASTR_GRUPO) 
                    {
                        setEdit(false);
                        setAmplifier(dataToCheck);
                        controlDataList('update',dataToCheck);
                    }
                    else
                    {
                        setOpenToast(true);
                        controlActionButton(dataToCheck.dS_DL, action);
                    }
                    break;
                case "delete":
                    if (!integrityCheck) 
                    {
                        setEdit(false);
                        controlDataList('delete',dataToCheck);
                    }
                    else
                    {
                        setOpenToast(true);
                        controlActionButton(dataToCheck.dS_DL, 'delete');
                    }
                    break;
                default:
                    break;
            }

        }, time);
    }

    const controlActionButton = async function (radioId, action) {
        
        let focusBtn = document.querySelector(`#${action}-amplifier-${radioId}`);
        let anotherBtns = !!focusBtn && focusBtn.parentElement.querySelectorAll(`button:not(#${action}-support)`);
        
        try
        {
            setLockEdit(true);

            if (!!focusBtn && !!anotherBtns)
            {
                focusBtn.classList.toggle("button--loading");
                anotherBtns.forEach(btn => {
                    btn.classList.toggle("button--block");
                });
            }

            switch (action)
            {
                case "synchronize":
                    await synchronizeAmplifierData(radioId);
                    break;
                case "update":
                    await updateAmplifierData(radioId);
                    break;
                case "delete":
                    await deleteAmplifierData(radioId);
                    break;
                default:
                    break;
            }

        }
        catch (error)
        {
            let response = Helper.TryParseJson(error);

            if (response)
            {
                console.log("🚀 ~ controlActionButton ~ error:", response.message)
                window.alert(response.message);
            }
            else
            {
                console.log("🚀 ~ controlActionButton ~ error:", error)
            }
        }
        finally
        {
            if (focusBtn.classList.contains("button--loading"))
            {
                focusBtn.classList.toggle("button--loading");
                anotherBtns.forEach(btn => {
                    btn.classList.toggle("button--block");
                });
                setLockEdit(false);
            }
        }

    }

    const controlDataList = (action, amplifier) => {
        let auxList = [];

        switch (action)
        {
            case "delete":
                if (props.type === "central")
                {
                    props.setCentral({});
                }
                else if (props.type === "modem")
                {
                    props.setModem({});
                }
                else
                {
                    auxList = props.amplifierList.filter((a) => a.dS_DL !== amplifier.dS_DL);
                }
                break;
            case "add":
                auxList = props.amplifierList.push(amplifier);
                break;
            default:
                auxList = props.amplifierList;
                auxList.forEach((a) => {
                    if(a.dS_DL === amplifier.dS_DL)
                    {
                        a = amplifier;
                    }
                });
                break;
        }

        props.setAmplifiersList(auxList);
    }

    const handleNameChange = (e) => {
        setAmplifierName(e.target.value);
    };

    const handleGroupChange = (e) => {
        setSelectedGroup(e.target.value);
    };

    const handleCloseToast = (e) => {
        setOpenToast(false);
    }

    useEffect(() => {
        setEdit(false);
        setAmplifier(props.amplifier);
        setAmplifierName(props.amplifier.dS_NOME);
        setSelectedGroup(props.amplifier.fK_VL_CADASTR_GRUPO);

        if (props.amplifier.fK_VL_CADASTR_GRUPO !== undefined) 
        {
            setLocation(props.amplifier.fK_VL_CADASTR_GRUPO.slice(12) + " - " + props.amplifier.dS_LOCALIZACAO);
        }

    }, [props.amplifier])

    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                open={openToast}
                onClose={handleCloseToast}
                autoHideDuration={5000}
                message="Nova tentativa de atualização"
                key={"amplifier-update"}
            />
            <InfoBox className={props.type !== "amplificador" ? "info-box--full info-box--curved mx-sm-0 mb-sm-2" : "info-box--curved"} status={amplifier.dS_STATUS}>
                <InfoBox.Header>
                    <div class="amplifier-card--row">
                        <figure class="amplifier-card__icon">
                            {props.type === "amplificador" &&
                                <>
                                {
                                amplifier.dS_STATUS === "online" 
                                ? <Router sx={{ color: "#34C759" }} /> 
                                : amplifier.dS_STATUS === "buscando" 
                                ? <Router sx={{ color: "#5ACBFA" }} /> 
                                : <Router sx={{ color: "#FF3B30" }} />
                                } 
                                </>
                            }
                            {props.type === "central" &&
                                <>
                                {
                                amplifier.dS_STATUS === "online" 
                                ? <i className="fa fa-tablet fa-3x color-success" aria-hidden="true"></i> 
                                : amplifier.dS_STATUS === "buscando" 
                                ? <i className="fa fa-tablet fa-3x color-info" aria-hidden="true"></i>
                                : <i className="fa fa-tablet fa-3x color-danger" aria-hidden="true"></i>
                                }
                                </>
                            }
                            {props.type === "modem" &&
                                <>
                                {
                                amplifier.dS_STATUS === "online" 
                                ? <i className="fa fa-usb fa-rotate-270 fa-2x color-success" aria-hidden="true"></i> 
                                : amplifier.dS_STATUS === "buscando" 
                                ? <i className="fa fa-usb fa-rotate-270 fa-2x color-info" aria-hidden="true"></i>
                                : <i className="fa fa-usb fa-rotate-270 fa-2x color-danger" aria-hidden="true"></i>
                                }
                                </>
                            }
                        </figure>
                        <div class="amplifier-card__content">
                            <h5 class="amplifier-card__title">{Object.keys(amplifier).length === 0 ? '#' : String(amplifier.dS_DL).toUpperCase()}</h5>
                            <p class="amplifier-card__status">{amplifier.dS_STATUS === "online" ? "online/sincronizado" : amplifier.dS_STATUS === "buscando" ? "online/sincronizando" : "offline"}</p>
                        </div>
                        { process.env.REACT_APP_MODE === "desktop" &&
                        <div class="amplifier-card__actions">
                            <button type="button" class="button button--sm" onClick={enableEdit} disabled={lockEdit}>
                                <i class="fa fa-pencil fa-lg button__icon" aria-hidden="true"></i>
                            </button>
                        </div>
                        }
                    </div>
                </InfoBox.Header>
                <InfoBox.Body>
                    <div class="amplifier-card">
                        <Box justifyContent="center" textAlign="justify">
                            {edit && props.type === "amplificador"
                            ?
                            <FormControl 
                                controlType='text' 
                                controlLabel='Nome'
                                controlLabelPosition='vertical'
                                controlId={`amplifier-name-${amplifier.dS_DL}`} 
                                controlPlaceholder="Digite um novo nome"
                                controlValue={amplifierName}
                                controlDisabled={false}
                                controlMaxLength={50}
                                onChange={handleNameChange}
                            />
                            :
                            <>
                                <Typography variant='body2' color='primary'>Nome</Typography>
                                <p class="mt-0 mb-1">{!amplifier.dS_NOME ? "Sem Nome Registrado" : amplifier.dS_NOME}</p>
                            </>
                            }
                        </Box>
                        { props.type === "amplificador" &&
                        <Box justifyContent="center" textAlign="justify">
                            {edit 
                            ?
                            <FormControl 
                                controlType='select' 
                                controlLabel='Grupo'
                                controlLabelPosition='vertical'
                                controlId={`amplifier-group-${amplifier.dS_DL}`} 
                                controlPlaceholder="Escolha um novo grupo"
                                controlDisabled={false}
                                onChange={handleGroupChange}
                            >
                                <FormControl.Options>
                                    <option value="" disabled>Escolha um novo grupo</option>
                                    {props.groupList.map((option, index) => (
                                        <option 
                                            key={index}
                                            value={option.vL_CADASTRO}
                                            selected={selectedGroup === option.vL_CADASTRO}
                                        >
                                            {option.dS_LOCALIZACAO === "Todos" ? null : option.vL_CADASTRO.slice(12) + " - "} {option.dS_LOCALIZACAO ? option.dS_LOCALIZACAO : "Sem nome registrado"}
                                        </option>
                                    ))}
                                </FormControl.Options>
                            </FormControl>
                            :
                            <>
                                <Typography variant='body2' color='primary'>Grupo</Typography>
                                <p class="mt-0 mb-1">{location.split(" - ")[1] === "" ? location.split(" - ")[0] + " - Sem Nome Registrado" : location}</p> 
                            </>
                            }
                        </Box>
                        }
                        {/* Talvez otimizar a apresentação do horário de atualização no futuro */}
                        {process.env.REACT_APP_MODE === "web" &&
                        <Box justifyContent="center" textAlign="justify">
                            <Typography variant='body2' color='primary'>Última atualização</Typography>
                            <Typography variant='body2' color='white'>
                            {!!amplifier.dT_AMPLIFICADOR
                            ? new Intl.DateTimeFormat("pt-BR",{dateStyle: "short", timeStyle: "medium"}).format(new Date(amplifier.dT_AMPLIFICADOR))
                            : <>-</>
                            }
                            </Typography> 
                        </Box>
                        }
                    </div>
                </InfoBox.Body>
                <InfoBox.Footer>
                    <div class={edit ? "amplifier-card amplifier-card--footer amplifier-card--actions" : "amplifier-card amplifier-card--footer"}>
                        <div class="buttons-container buttons-container--right">
                            <button 
                                type="button" 
                                id={`delete-amplifier-${amplifier.dS_DL}`}
                                class="button button--danger button--sm" 
                                title={`Excluir amplificador ${amplifier.dS_DL}`} 
                                onClick={() => window.confirm(`Você realmente quer excluir esse equipamento?`) ? controlActionButton(amplifier.dS_DL,"delete") : setEdit(false)}
                            >
                                <i class="fa fa-spinner fa-spin fa-lg button__loading"></i>
                                <i class="fa fa-trash fa-lg button__icon" aria-hidden="true"></i>
                            </button>
                            <button 
                                type="button" 
                                id={`synchronize-amplifier-${amplifier.dS_DL}`}
                                class="button button--primary button--sm" 
                                title={`Sincronizar dados do amplificador ${amplifier.dS_DL} para o WEB`} 
                                onClick={() => controlActionButton(amplifier.dS_DL,"synchronize")}
                            >
                                <i class="fa fa-spinner fa-spin fa-lg button__loading"></i>
                                <i class="fa fa-refresh fa-lg button__icon" aria-hidden="true"></i>
                            </button>
                            {props.type === "amplificador"
                            ?
                            <button 
                                type="button" 
                                id={`update-amplifier-${amplifier.dS_DL}`} 
                                class="button button--primary button--sm" 
                                title={`Salvar alterações no amplificador ${amplifier.dS_DL}`} 
                                onClick={() => { controlActionButton(amplifier.dS_DL,"update") }}
                            >
                                <i class="fa fa-spinner fa-spin fa-lg button__loading"></i>
                                <i class="fa fa-floppy-o fa-lg button__icon" aria-hidden="true"></i>
                            </button>
                            :
                            null
                            }
                        </div>
                    </div>
                </InfoBox.Footer>
            </InfoBox>
        </>
    )
}

AmplifierCard.propTypes = {
    type: PropTypes.oneOf(['amplificador', 'central', 'modem']),
}

export default AmplifierCard;