import { useState, useEffect, forwardRef } from "react" //DIGOLA forwardref????
import { Card, CardContent, Box, Typography, CardHeader, FormControlLabel, Checkbox } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import PowerOffIcon from '@mui/icons-material/PowerOff';

const useStyles = makeStyles({
    pageCentral: {
        minHeight: "100vh",
    },
    cardCentral: {
        padding: 0,
        width: '100%', //50vw
        margin: 'auto',
        marginTop: '10px',
        marginBottom: 20
    },
})


export default function SemRedeACChecker(props) {
    const classes = useStyles()

    const [showSemRedeAC, setShowSemRedeAC] = useState(false) 

    const user = localStorage.getItem("user") !== null ? JSON.parse(localStorage.getItem("user")) : null;

    useEffect(() => {
        verifyUserShowSemRedeAC()
    }, []);
    

    const verifyUserShowSemRedeAC = () => {
        fetch(`${process.env.REACT_APP_URL}/semredeac/get/${user.id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            },
        })
            .then(response => response.json())
            .then(data => {
                setShowSemRedeAC(data.booL_SHOWREDEAC) 

            })
            .catch(error => {

            });
    }   

    const checkedOptionShowSemRedeAC = () => {
        setShowSemRedeAC(!showSemRedeAC);
        show(!showSemRedeAC);
    };
     
    const show = (value) => {
        sessionStorage.setItem("showNoACDC", value)
        fetch(`${process.env.REACT_APP_URL}/semredeac/put?id=${user.id}&showredeac=${value}`, { 
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            }
        });
    };

    return (
        <Card className={classes.cardCentral}>
            <CardHeader
                avatar={<PowerOffIcon color="primary" />}
                title={"Status da Central - Rede AC"}
            />
            <Box marginTop={"10px"} marginBottom={"10px"} marginLeft={"50px"}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={showSemRedeAC}
                            onChange={checkedOptionShowSemRedeAC}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                    label="Mostrar estado da Rede AC na tela de sistema"
                />
            </Box>
        </Card>

    )

}