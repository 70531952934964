import { useState, useEffect } from 'react'
import { makeStyles } from "@material-ui/core/styles"
import { styled, useTheme } from "@mui/material/styles"
import { Box, Typography } from '@mui/material'

/*export default function Footer(props) {
    const buildVersion = useState(process.env.REACT_APP_BUILD_VERSION)*/


const useStyles = makeStyles((theme) => {
    return {
        footerLicence: {
            backgroundColor: "#30D253",
            color: "#121312",
        },
        footerLicenceWarning: {
            backgroundColor: "#F42F3E",
            color: "#fffffff",
        }
    }
})


const FooterBar = styled('footer', {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    width: "100%",
    position: "fixed",
    bottom: 0,
    zIndex: "1",
    alignItems: "center",
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        [theme.breakpoints.up('sm')]: {

            width: `calc(100% - 240px)`,
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

export default function Footer(props) {
    const buildVersion = useState(process.env.REACT_APP_BUILD_VERSION)

    const [hasUpdate, setHasUpdate] = useState({ update: false, internet: false, version: "" });
    const [showUpdate, setshowUpdate] = useState(true);
    const user = localStorage.getItem("user") !== null ? JSON.parse(localStorage.getItem("user")) : "";

   /* useEffect(() => {
        verifyIfUserExists();
    },[]);*/

    useEffect(() => {
        localStorage.removeItem("lastCheckDate");

        const checkDaily = () => {
            const lastCheck = localStorage.getItem('lastCheckDate');
            const today = new Date().toISOString().split('T')[0]; // Current date in YYYY-MM-DD format

            if (lastCheck === null || lastCheck !== today) { //aqui pra teste
                performDailyServiceCall();

                localStorage.setItem('lastCheckDate', today);
            }
        };

        checkDaily();

        const intervalId = setInterval(() => {
            checkDaily();
        }, 15000 ); 

        return () => clearInterval(intervalId);
    }, []);


    const token = localStorage.getItem("token") !== null ? localStorage.getItem("token") : "";

    const BgColour = () => {

        if (!hasUpdate.update || !showUpdate || process.env.REACT_APP_MODE === 'web') {
            return "#212121" 

        }
        else {
            if (user.role.tecnico === true ) return "#F58634" //laranja
            else return "#5ACBFA"; //azul
        }
    }

    const performDailyServiceCall = () => {

        VerifyUpdate();
    };



    const VerifyUpdate = async function () {

        try {
            const systemData = JSON.parse(localStorage.getItem("system"));
            const licenseType = localStorage.getItem("licenseType");

            console.log(systemData);
            console.log(licenseType);

            if (!systemData.licenceKey) {
                throw Error("Não foi possível obter a licença do produto");
            }
            if (!licenseType) {
                throw Error("Não foi possível obter o tipo de licença do produto");
            }

            const response = await fetch(`${process.env.REACT_APP_URL}/licences/verify-version-to-update`, {
                method: 'POST',
                headers: {
                    "Content-type": "application/json",
                    "Authorization": "Bearer " + token //pegar token 
                },
                body: JSON.stringify({
                    licenseKey: systemData.licenceKey,
                    licenseType: licenseType,
                    licenseRevision: process.env.REACT_APP_BUILD_VERSION
                })
            });

            if (!response.ok) {
                throw Error("Não foi possível verificar se há updates!");
            }

            const res = await response.json();

            setHasUpdate(res);

        
            verifyUserUpdate();


        }
        catch (error) {
           // console.log("🚀 ~ VerifyUpdate_Footer ~ error:", error)
        }
    }
    const verifyUserUpdate = () => {
        fetch(`${process.env.REACT_APP_URL}/usernotification/get/${user.id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            },
        })
            .then(response => response.json())
            .then(data => {
                setshowUpdate(data.booL_ISNOTIFIABLE)
                //console.log('ShowNotification:', data);
            })
            .catch(error => {
                //console.error('UserNotification Error showUpdate:', error);
            });
    }
   /* const verifyIfUserExists = () => {
        const serial = sessionStorage.getItem("serial");
        fetch(`${process.env.REACT_APP_URL}/semredeac/create-if-doesnt-exists?panelSerial=${serial}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(user)
        })
            .then(response => response.json())
            .then(data => {

            })
            .catch(error => {

            });
    }*/

    const UpdateSystem = () => {
        document.querySelector('.hidden[data-action="update-system"]').click();
    };    

    return (
        <FooterBar open={props.open}>
            <Box
                sx={{
                    width: "100%",
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0.5rem',
                    backgroundColor: BgColour,
                    fontWeight: 'bold',
                    color: process.env.REACT_APP_MODE === 'desktop' && hasUpdate.update && showUpdate ? '#313131' : 'inherit', 
                }}
            >
                <Typography textAlign="center" variant="body2">Central: {props.serial}</Typography>
                {process.env.REACT_APP_MODE === 'desktop' && hasUpdate.update && showUpdate ? (
                    <Box
                        sx={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <button
                            className="button button--transparent mx-auto"
                            style={{ "color": "#313131", "textDecoration": "underline" }}
                            onClick={() => { UpdateSystem() }}
                        >
                            NOVA VERSÃO ENCONTRADA! CLIQUE AQUI PARA ATUALIZAR!
                        </button>
                    </Box>
                ) : null}
                {process.env.REACT_APP_MODE === 'desktop' ? (
                    <Typography textAlign="end" variant="body2">v{buildVersion}</Typography>
                ) : null}
            </Box>
        </FooterBar>
    )

}