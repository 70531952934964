import Group from "../models/Group";

class GroupsProvider {
    constructor() {
        this.groups = []; // Lista de Grupos do Sistema 
        this.subscribers = []; // Lista de funções para notificar atualizações
    }

    loadGroups = async() => {
        try 
        {
            const request = await fetch(`${process.env.REACT_APP_URL}/group/all`, {
                method: 'POST',
                headers: {
                    "content-type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("token")}` 
                },
                body: JSON.stringify({
                    "serialNumber": sessionStorage.getItem("serial"),
                })
            });

            if(!request.ok) throw Error("Não foi possível obter a lista de grupos.");
            
            const response = await request.json();
            this.groups = response.map(groupData => new Group(groupData));
        }
        catch (error)
        {
            console.error("🚀 ~ GroupsProvider ~ loadGroups=async ~ error:", error.message)
        }
    }

    // Atualiza a lista de grupos
    updateGroupsList(groups)
    {
        groups.forEach(newGroup => {
            const existingGroup = this.groups.find(group => group.id === newGroup.id);
            if (existingGroup) {
                existingGroup.local = newGroup.local; // Atualiza apenas a propriedade local
            }
        });
        this.notifySubscribers();
    }

    // Retorna o Grupo Geral
    getGeneralGroup()
    {
        return new Group({
            id: 0,
            fK_CENTRAL_GRUPO: sessionStorage.getItem("serial"),
            vL_CADASTRO: `${sessionStorage.getItem("serial")}0`,
            dS_LOCALIZACAO: "Geral",
            amplifier: null,
            register: null,
            bL_STATUS: true,
        });
    }

    // Retorna o grupo relacionado a posição informada
    getGroupByPosition(position)
    {
        let group = this.groups.find((group) => group.position === position);
        
        if (!group)
        {
            console.log("🚀 ~ GroupsProvider ~ Não encontrado")
            group = this.getGeneralGroup();
        }

        return group;
    }

    // Adiciona um subscriber
	subscribe(callback) {
        this.subscribers.push(callback);
    }

    // Remove um subscriber
    unsubscribe(callback) {
        this.subscribers = this.subscribers.filter((cb) => cb !== callback);
    }

    // Notifica todos os inscritos
    notifySubscribers() {
        this.subscribers.forEach((callback) => callback(this.getState()));
    }

    // Retorna o estado atual
    getState() {
        return {
            groups: this.groups
        };
    }
}

const groupsProvider = new GroupsProvider();
export default groupsProvider;