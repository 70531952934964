import React, { useState, useEffect, useCallback, ChangeEvent } from "react"
import { Grid, Button, Modal, Container, Typography, Autocomplete, TextField, InputAdornment, Checkbox, Box, FormControlLabel, IconButton, Tooltip, Card, CardHeader, CardContent, CardActionArea, LinearProgress } from '@mui/material'
import { Router } from '@mui/icons-material'
import AmplifierCard from "../components/AmplifierCard"
import makeStyles from "@mui/styles/makeStyles"
import { Search } from '@mui/icons-material'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import CloseIcon from '@mui/icons-material/Close'
import NumberSelector from "../components/NumberSelector"
import Helper from "../providers/Helpers"

const useStyles = makeStyles({
    pageAmplifier: {
        minHeight: "100vh",
    },
    counter: {
        padding: '1rem',
        marginLeft: 0,
    },
    amplifierHeaderGrid: {
        paddingRight: "10vw",
        paddingLeft: "10vw",
        paddingBottom: "2vh"
    },
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        border: '3px solid #FFF',
        borderRadius: '20px !important',
    }
})

export default function Amplifiers(props) {
    let bridge = sessionStorage.getItem("bridgeMode")
    let informationGrid = process.env.REACT_APP_MODE === 'desktop' ? 3 : 4
    const classes = useStyles()
    const [amplifiers, setAmplifiers] = useState([])
    const [amplifierList, setAmplifiersList] = useState([])
    const [autocompleteList, setAutocompleteList] = useState([])
    const [groups, setGroups] = useState([])
    const [checked, setChecked] = useState(sessionStorage.getItem("bridgeMode") === "true" ? true : false)
    const [individualTimer, setIndividualTimer] = useState(500)
    const [broadcastTimer, setBroadcastTimer] = useState(80000)
    const [open, setOpen] = useState(false)
    const [syncTimer, setSyncTimer] = useState()
    const [offTimer, setOffTimer] = useState()
    const [searchTimer, setSearchTimer] = useState()
    const [offLineTimer, setOfflineTimer] = useState()
    const [modem, setModem] = useState({})
    const [central, setCentral] = useState({})
    const [isLoading, setIsLoading] = useState(true);
    const [hideAmplifier, setHideAmplifier] = useState(true);
    const [syncing, setSyncing] = useState(false);
    const [desktopStatus, setDesktopStatus] = useState("não informado");

    var [amplifierCounter, setCommandCounter] = useState(1)

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const getActiveFilterAmplifiers = useCallback(function (list) {
        let triggers = document.querySelectorAll('input[name="filter-display"]');
        let flag = false;

        triggers.forEach(trigger => {
            if (!!trigger.checked) {
                flag = true;
                updateRenderList(trigger.value, list);
            }
        });

        if (!flag) {
            updateRenderList("all", list);
        }
    }, []);

    const updateRenderList = function (status, list) {
        document.querySelector("#filter-amplifier").value = "";

        let auxList = [];

        list.forEach(item => {
            if (status !== "all") {
                if (item.dS_STATUS === status) {
                    auxList.push(item);
                }
            }
            else {
                auxList.push(item);
            }
        });

        setAmplifiers(auxList);
        setAutocompleteList(auxList);
    }

    const autocomplete = (event) => {
        //vinculado ao filtro
        if (event.target.innerText === undefined || event.target.innerText === "") {
            setAmplifiers(autocompleteList)
        }
        else {
            let filtered = amplifiers.find((item) => event.target.innerText.includes(item.dS_DL));

            if (!!filtered) {
                setAmplifiers(Array(filtered))
            }
        }
    }

    const amplifierCounterList = (amplifiers) => {
        return amplifiers.length;
    }

    const amplifiersOnline = (amplifiers) => {
        let count = amplifiers.filter(amplifier => {
            return amplifier.dS_STATUS === "online" ? amplifier : null
        })

        return count.length
    }

    const amplifiersSearching = (amplifiers) => {
        let count = amplifiers.filter(amplifier => {
            return amplifier.dS_STATUS === "buscando" ? amplifier : null
        })

        return count.length
    }

    const amplifiersOffline = (amplifiers) => {
        let count = amplifiers.filter(amplifier => {
            return amplifier.dS_STATUS === "offline" ? amplifier : null
        })

        return count.length
    }

    const amplifierMode = (value) => {
        fetch(`${process.env.REACT_APP_URL}/userfetchdata/change/status?selector=${value}`, {
            method: "GET",
            headers: {
                "content-type": "application/json",
                "Authorization": "Bearer " + props.token
            }
        })
    }

    const clearAmplifierList = async () => {
        //Função para desenvolvedor
        try {
            if (!!localStorage.getItem("token") && !!sessionStorage.getItem("serial")) {
                const response = await fetch(`${process.env.REACT_APP_URL}/amplifier/delete-all?serialNumber=${sessionStorage.getItem("serial")}`, {
                    method: "GET",
                    headers: {
                        "content-type": "application/json",
                        "Authorization": "Bearer " + localStorage.getItem("token")
                    }
                });

                if (response.ok) {
                    setModem({})
                    setCentral({})
                    setAmplifiers([])
                    setAmplifiersList([])
                    setAutocompleteList([])

                    Helper.Notification(`Os dados de amplicadores relacionados ao número de série ${sessionStorage.getItem("serial")} foram EXCLUÍDOS.`, "Rede");
                }
                else {
                    throw Error("Não foi possível limpar os dados de amplificadores!");
                }
            }
            else {
                throw Error("Não há credenciais para fazer a requisição!");
            }
        }
        catch (error) {
            console.log("🚀 ~ clearAmplifierList ~ error:", error)
        }
    }

    const synchronizeAmplifierList = async (dataList) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_URL}/amplifier/synchronize-all`, {
                method: 'POST',
                headers: {
                    "content-type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(dataList)
            });

            if (!response.ok) {
                throw Error(JSON.stringify({ type: "server", message: `Não foi possível sincronizar a lista de amplificadores` }));
            }

            const res = await response.json();

            if (!!res) {
                Helper.Notification('Sincronização de dados realizada com sucesso! 🤙', 'Sincronização', 500);
            }
        }
        catch (error) {
            let response = Helper.TryParseJson(error);

            if (response) {
                console.log("🚀 ~ synchronizeAmplifierData ~ error:", response.message)

                if (JSON.parse(error).type === "server") {
                    window.alert(response.message);
                }
            }
            else {
                console.log("🚀 ~ synchronizeAmplifierData ~ error:", error)
            }
        }
    }

    const changeBroadcastTimer = () => {
        fetch(`${process.env.REACT_APP_URL}/userfetchdata/change/broadcast?timer=${broadcastTimer}`, {
            method: "GET",
            headers: {
                "content-type": "application/json",
                "Authorization": "Bearer " + props.token
            }
        })
    }

    const changeIndividualTimer = () => {
        fetch(`${process.env.REACT_APP_URL}/userfetchdata/change/individual?timer=${individualTimer}`, {
            method: "GET",
            headers: {
                "content-type": "application/json",
                "Authorization": "Bearer " + props.token
            }
        })
    }

    const handleCheckBox = () => {
        var canChange = !checked
        setChecked(!checked)
        sessionStorage.removeItem("bridgeMode")
        sessionStorage.setItem("bridgeMode", canChange)

        if (canChange) {
            amplifierMode(6)
        } else {
            amplifierMode(3)
        }
    }

    const changeTimer = async () => {
        try {
            let btn = document.querySelector("#save-timer");

            if (!!btn) {
                btn.classList.add("button--loading");
            }

            const response = await fetch(`${process.env.REACT_APP_URL}/amplifier/timer?searching=${syncTimer}&offline=${offTimer}`, {
                method: "GET",
                headers: {
                    "content-type": "application/json",
                    "Authorization": "Bearer " + props.token
                }
            });

            if (!response.ok) {
                let message = "Não foi possível finalizar a requisição de alteração do Tempo de Sincronização.";

                window.alert(message);

                throw new Error(message);
            }

            const res = await response.json();

            if (!res.status) {
                window.alert(res.message);

                throw new Error(res.message);
            }
            else {
                setTimeout(() => {
                    if (!!btn) {
                        btn.classList.remove("button--loading");
                    }
                    setOpen(false);
                    setSearchTimer(syncTimer);
                    setOfflineTimer(offTimer);
                }, 1000)
            }
        }
        catch (error) {
            console.log("🚀 ~ changeTimer ~ error:", error);
        }
    }

    const traitAmplifiersType = (amplifiers) => {
        console.log("🚀 ~ traitAmplifiersType ~ amplifiers:", amplifiers);

        let listAutocomplete = [];
        let list = [];

        amplifiers.forEach(item => {
            if (item.dS_NOME !== "Modem" && item.dS_NOME !== "Central" && item.dS_NOME !== "undefined") {
                listAutocomplete.push({ label: item.dS_DL })
            }

            if (!!item.dS_NOME || item.dS_NOME !== "undefined") {
                switch (item.dS_NOME) {
                    case "Central":
                        setCentral(item);
                        break;
                    case "Modem":
                        setModem(item);
                        break;
                    default:
                        item.show = true;
                        list.push(item);
                        break;
                }
            }
        });

        setAmplifiersList(list);
        setAutocompleteList(listAutocomplete);

        getActiveFilterAmplifiers(list);
    }

    const getAmplifiersBySerial = useCallback(async () => {
        if (!!searchTimer && !sessionStorage.getItem('isEditing')) {
            //setIsLoading(true);
            try {
                if (!!localStorage.getItem("token") && !!sessionStorage.getItem("serial")) {
                    //window.alert("BUSCANDO PO");
                    const response = await fetch(`${process.env.REACT_APP_URL}/amplifier/get-by-serial`, {
                        method: "POST",
                        headers: {
                            "content-type": "application/json",
                            "Authorization": "Bearer " + localStorage.getItem("token")
                        },
                        body: JSON.stringify(sessionStorage.getItem("serial"))
                    });

                    if (!response.ok) {
                        throw Error("Não foi possível obter dados de Amplificadores!");
                    }

                    const res = await response.json();

                    if (!!res) {
                        traitAmplifiersType(res.amplifiers);
                    }
                }
                else {
                    throw Error("Sem credenciais para realizar a busca!");
                }
            }
            catch (error) {
                console.log("🚀 ~ getAmplifiersBySerial ~ error:", error)
            }
            finally {
                setIsLoading(false);
                //setTimeout(getAmplifiersBySerial, (searchTimer * 60) * 1000);

                setTimeout(getAmplifiersBySerial, 20000);
            }
        }
        else {
            getAmplifierTimer();
        }
    }, [searchTimer])

    const getGroupsBySerial = async () => {
        try {
            if (!!localStorage.getItem("token") && !!sessionStorage.getItem("serial")) {
                const response = await fetch(`${process.env.REACT_APP_URL}/group/groups`, {
                    method: 'POST',
                    headers: {
                        "content-type": "application/json",
                        "Authorization": "Bearer " + localStorage.getItem("token")
                    },
                    body: JSON.stringify({
                        "serialNumber": sessionStorage.getItem("serial"),
                    })
                });

                if (!response.ok) {
                    throw Error("Não foi possível obter dados dos grupos");
                }

                const res = await response.json();

                if (!!res) {
                    setGroups(res)
                }
            }
            else {
                throw Error("Não há credenciais para fazer a requisição!");
            }
        }
        catch (error) {
            console.log("🚀 ~ getGroupsBySerial ~ error:", error)
        }
    }

    const getAmplifierTimer = async () => {
        try {
            if (!!localStorage.getItem("token")) {
                const response = await fetch(`${process.env.REACT_APP_URL}/amplifier/timer/converted`, {
                    method: 'GET',
                    headers: {
                        "content-type": "application/json",
                        "Authorization": "Bearer " + localStorage.getItem("token")
                    }
                });

                if (!response.ok) {
                    throw Error("Não foi possível obter as informações de tempo!");
                }

                const res = await response.json();

                if (!!res) {
                    setSearchTimer(res.searching)
                    setOfflineTimer(res.offline)
                    setSyncTimer(res.searching)
                    setOffTimer(res.offline)
                }
            }
            else {
                throw Error("Não há credenciais para fazer a requisição!");
            }
        }
        catch (error) {
            console.log("🚀 ~ getAmplifierTimer ~ error:", error)
        }
    }

    const getBroadcastMode = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_URL}/userfetchdata/broadcast/mode`, {
                method: 'GET',
                headers: {
                    "content-type": "application/json"
                }
            });

            if (!response.ok) {
                throw Error("Não foi possível obter as informações do Modo de Broadcast!");
            }

            const res = await response.json();

            if (!!res) {
                if (res === 6 || res === 9) {
                    setChecked(true)
                }
            }
        }
        catch (error) {
            console.log("🚀 ~ getBroadcastMode ~ error:", error)
        }
    }

    const controlActionButton = async (action) => {
        let focusBtn = document.querySelector(`#${action}-support`);
        let anotherBtns = focusBtn.parentElement.querySelectorAll(`button:not(#${action}-support)`);

        try {
            focusBtn.classList.toggle("button--loading");
            anotherBtns.forEach(btn => {
                btn.classList.toggle("button--block");
            });

            switch (action) {
                case "synchronize":
                    await synchronizeAmplifierList(amplifierList);
                    break;
                case "delete":
                    await clearAmplifierList();
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.log("🚀 ~ controlActionButton ~ error:", error)
        }
        finally {
            if (focusBtn.classList.contains("button--loading")) {
                focusBtn.classList.toggle("button--loading");
                anotherBtns.forEach(btn => {
                    btn.classList.toggle("button--block");
                });
            }
        }
    }

    const CheckDesktopStatusFromWeb = useCallback(async () => {
        if (process.env.REACT_APP_MODE === "web" && !!localStorage.getItem("token") && !!sessionStorage.getItem("serial")) {
            try {
                const response = await fetch(`${process.env.REACT_APP_URL}/localstatus/get-by-serial?serialNumber=${sessionStorage.getItem("serial")}`, {
                    method: "GET",
                    headers: {
                        "content-type": "application/json",
                        "Authorization": "Bearer " + localStorage.getItem("token")
                    }
                });

                if (!response.ok) {
                    throw new Error("Falha ao buscar dados do Modo de Operação");
                }

                const res = await response.json();

                if (res.isOpen) {
                    setDesktopStatus("online");
                }
                else {
                    setDesktopStatus("offline");
                }

                console.log("🚀 ~ CheckDesktopStatusFromWeb ~ res:", res)
            }
            catch (error) {
                console.log("🚀 ~ GetDesktopStatusBySerial ~ error:", error)
            }
            finally {
                setTimeout(CheckDesktopStatusFromWeb, 10000);
            }

            return () => { console.log("Finish CheckDesktopStatusFromWeb"); }
        }
        else {
            return () => { console.log("Ambiente Desktop identificado, pulando CheckDesktopStatusFromWeb"); }
        }
    }, [])

    useEffect(() => {
        getAmplifiersBySerial();
        getGroupsBySerial();
        getAmplifierTimer();
        getBroadcastMode();
    }, [getAmplifiersBySerial])

    useEffect(CheckDesktopStatusFromWeb, [CheckDesktopStatusFromWeb]);

    useEffect(() => {
        getActiveFilterAmplifiers(amplifierList);
    }, [amplifierList])

    //TODO atualizar autocomplete quando chegam novos amplificadores
    useEffect(() => {
        let auxAmplifiers = [...amplifierList]

        auxAmplifiers.forEach(amplifier => {
            if (amplifier.dS_DL === props.amplifierChange.amplifier) {
                amplifier.dS_STATUS = props.amplifierChange.status;
            }
        });

        getActiveFilterAmplifiers(auxAmplifiers);

    }, [props.amplifierChange])

    useEffect(() => {
        if (process.env.REACT_APP_MODE === 'desktop') {
            if (!!props.modemStatus.status && !props.serialPortError) {
                setHideAmplifier(false);
            }
            else {
                setHideAmplifier(true);
            }
        }
        else {
            if (!!props.modemConnected && desktopStatus === 'online') {
                setHideAmplifier(false);
            }
            else {
                setHideAmplifier(true);
            }
        }


    }, [props.modemStatus, props.modemConnected, props.serialPortError, desktopStatus])


    //old Sync
    //useEffect(() => {
    //    if (!props.modemStatus.status && !sessionStorage.getItem("avoidSyncModem")) {
    //        setSyncing(true);
    //        setTimeout(() => {
    //            setSyncing(false);
    //        }, 20000);
    //    } else if (props.modemStatus.status && !!props.modemStatus.status) {
    //        setSyncing(false);
    //    }
    //}, [props.modemStatus.status]);

    useEffect(() => {
        if (props.timer) {
            setSyncing(true);
        }
        else {
            setSyncing(false);
        }
    }, [props.timer]);

    useEffect(() => { sessionStorage.removeItem('isEditing') }, [])

    return (
        <main class="container container--min-height">
            {isLoading &&
                <LinearProgress color="secondary" style={{ position: "fixed", top: "66px", left: "0", width: "100%", zIndex: "1201" }} />
            }
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Card className={classes.modal}>
                    <CardHeader
                        title="Temporizadores"
                        action={
                            <IconButton onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        }
                    />
                    <CardContent>
                        <Typography>Selecione um valor para os parâmetros a seguir. O Tempo escolhido simboliza um valor em MINUTOS.</Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '10px', marginTop: '10px' }}>
                            <Typography variant="h6" sx={{ width: '100px' }}>Sinc.: </Typography>
                            <NumberSelector time={syncTimer} setValue={setSyncTimer} />
                        </Box>
                        {props.user.dS_NOME === 'suporte03deltafire' ?
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Typography variant="h6" sx={{ width: '100px' }}>Offline:  </Typography>
                                <NumberSelector time={offTimer} setValue={setOffTimer} />
                            </Box>
                            : null}

                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <button
                                id="save-timer"
                                class="button button--primary button--md"
                                data-action="save-timer"
                                title="Sincronizar lista de amplificadores para o WEB"
                                onClick={changeTimer}
                            >
                                <span class="button__label">Salvar</span>
                            </button>
                        </Box>
                    </CardContent>
                </Card>
            </Modal>

            <div className={hideAmplifier ? "hidden" : ""}>

                <Box sx={{ marginTop: '1rem' }}>

                    <center>
                        <label><h3>Dispositivos do Sistema</h3></label>
                    </center>

                    <Grid container spacing={3} justifyContent='space-around'>
                        <Grid key={0} item xs={12} md={12} lg={6}>
                            <AmplifierCard
                                type="central"
                                role={props.role}
                                groupList={groups}
                                groupName={central.dS_LOCALIZACAO}
                                amplifier={central}
                                amplifierList={amplifierList}
                                setAmplifiersList={setAmplifiersList}
                                setCentral={setCentral}
                                length={amplifiers.length}
                                counter={amplifierCounter++}
                            />
                        </Grid>

                        <Grid key={1} item xs={12} md={12} lg={6}>
                            <AmplifierCard
                                type="modem"
                                role={props.role}
                                groupList={groups}
                                groupName={modem.dS_LOCALIZACAO}
                                amplifier={modem}
                                amplifierList={amplifierList}
                                setAmplifiersList={setAmplifiersList}
                                setModem={setModem}
                                length={amplifiers.length}
                                counter={amplifierCounter++}
                            />
                        </Grid>
                    </Grid>
                </Box>

                <div class="amplifier-top mb-5">
                    <center>
                        <label><h3>Filtro</h3></label>
                    </center>
                    <form id="amplifiers-filter" class="amplifier-top__body filter-control">
                        {/* {process.env.REACT_APP_MODE === 'desktop' &&
                        <Tooltip title="Clique aqui para Editar os Temporizadores">
                            <Grid key={2} item xs={informationGrid} md={informationGrid} lg={informationGrid - 2}>
                                <IconButton color="#FFCC00" onClick={handleOpen}><AccessTimeIcon /></IconButton>
                            </Grid>
                        </Tooltip>
                    } */}

                        <input type="radio" id="filter-all-amplifiers" class="filter-control__trigger" name="filter-display" value="all" />
                        <label class="kpi kpi--filter" for="filter-all-amplifiers" onClick={() => { updateRenderList("all", amplifierList) }}>
                            <div class="kpi__header">
                                <span class="kpi__header__title">Amplificadores Encontrados</span>
                            </div>
                            <div class="kpi__body">
                                <Router className="kpi__body__icon" style={{ fill: "#FFF" }} />
                                <span class="kpi__body__value">{amplifierCounterList(amplifierList)}</span>
                            </div>
                        </label>

                        <input type="radio" id="filter-online-amplifiers" class="filter-control__trigger" name="filter-display" value="online" />
                        <label class="kpi kpi--filter" for="filter-online-amplifiers" onClick={() => { updateRenderList("online", amplifierList) }}>
                            <div class="kpi__header">
                                <span class="kpi__header__title">Online/Sincronizado</span>
                            </div>
                            <div class="kpi__body">
                                <Router className="kpi__body__icon" style={{ fill: "#34C759" }} />
                                <span class="kpi__body__value">{amplifiersOnline(amplifierList)}</span>
                            </div>
                        </label>

                        <input type="radio" id="filter-searching-amplifiers" class="filter-control__trigger" name="filter-display" value="buscando" />
                        <label class="kpi kpi--filter" for="filter-searching-amplifiers" onClick={() => { updateRenderList("buscando", amplifierList) }}>
                            <div class="kpi__header">
                                <span class="kpi__header__title">Online/Sincronizando</span>
                            </div>
                            <div class="kpi__body">
                                <Router className="kpi__body__icon" style={{ fill: "#5ACBFA" }} />
                                <span class="kpi__body__value">{amplifiersSearching(amplifierList)}</span>
                            </div>
                        </label>

                        <input type="radio" id="filter-offline-amplifiers" class="filter-control__trigger" name="filter-display" value="offline" />
                        <label class="kpi kpi--filter" for="filter-offline-amplifiers" onClick={() => { updateRenderList("offline", amplifierList) }}>
                            <div class="kpi__header">
                                <span class="kpi__header__title">Offline</span>
                            </div>
                            <div class="kpi__body">
                                <Router className="kpi__body__icon" style={{ fill: "#FF3B30" }} />
                                <span class="kpi__body__value">{amplifiersOffline(amplifierList)}</span>
                            </div>
                        </label>
                    </form>
                </div>

                <Grid className={process.env.REACT_APP_MODE === "desktop" ? "" : "mb-5"} key={0} item xs={12} md={12} lg={12} style={{ "display": "inline-block", "width": "100%" }}>
                    <Autocomplete
                        disablePortal
                        id="filter-amplifier"
                        options={autocompleteList}
                        getOptionLabel={(option) => `${option.dS_DL} - ${option.dS_NOME ? option.dS_NOME : 'Sem Nome Registrado'}` || `${option.dS_DL}`}
                        onChange={(event) => autocomplete(event)}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                placeholder="Pesquisar"
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (<InputAdornment position="start"><Search color="secondary" /></InputAdornment>)
                                }}
                            />
                        }
                    />
                </Grid>

                {process.env.REACT_APP_MODE === 'desktop' &&
                    <Grid className="mt-2" display={"flex"} alignItems={"center"} justifyContent={"space-between"} width={"100%"}>
                        <Box marginTop={"10px"} marginBottom={"10px"} marginLeft={"1px"}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checked}
                                        onChange={handleCheckBox}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                                label="Habilitar a Busca por Amplificador Bridge"
                            />
                        </Box>
                        <div id="amplifiers-support-tools" class="buttons-container">
                            <button
                                id="timer-configuration"
                                class="button button--primary button--sm"
                                data-action="timer-configuration"
                                title="Configurar Tempo de Sincronização"
                                onClick={handleOpen}>
                                <i class="fa fa-spinner fa-spin fa-lg button__loading"></i>
                                <i class="fa fa-clock-o fa-lg button__icon" aria-hidden="true"></i>
                            </button>
                            {(JSON.parse(localStorage.getItem("user")).dS_NOME.includes("@deltafire") || process.env.REACT_APP_ENVIROMENT === "development") &&
                                <>
                                    <button
                                        id="synchronize-support"
                                        class="button button--primary button--sm"
                                        data-action="synchronize-support"
                                        title="Sincronizar lista de amplificadores para o WEB"
                                        onClick={() => window.confirm("Será executado a sincronização de dados rede (LOCAL => WEB). Isso pode demorar um pouco.\n\nDeseja prosseguir?") ? controlActionButton("synchronize") : null}
                                    >
                                        <i class="fa fa-spinner fa-spin fa-lg button__loading"></i>
                                        <i class="fa fa-refresh fa-lg button__icon"></i>
                                    </button>
                                    {/* <button 
                            id="delete-support" 
                            class="button button--danger button--sm" 
                            data-action="delete-support"
                            title="Excluir todos os amplificadores" 
                            onClick={() => window.confirm("Você realmente quer EXCLUIR todos os amplificadores?\n\nEssa ação irá remover dados do LOCAL e da WEB!") ? controlActionButton("delete") : null}
                        >
                            <i class="fa fa-spinner fa-spin fa-lg button__loading"></i>
                            <i class="fa fa-trash fa-lg button__icon"></i>
                        </button> */}
                                </>
                            }
                        </div>
                    </Grid>
                }

                <div class="grid grid--left mt-5" data-grid-xl-columns="4" data-grid-lg-columns="3" data-grid-md-columns="3" data-grid-sm-columns="1" data-grid-xs-columns="1">
                    {amplifiers.length > 0
                        ?
                        amplifiers.map(amplifier => (
                            amplifier.dS_DL != null
                                ?
                                (
                                    <div class="grid__item grid__item--no-grow">
                                        <AmplifierCard
                                            type="amplificador"
                                            role={props.role}
                                            groupList={groups}
                                            groupName={amplifier.dS_LOCALIZACAO}
                                            amplifier={amplifier}
                                            amplifierList={amplifierList}
                                            setAmplifiersList={setAmplifiersList}
                                            length={amplifiers.length}
                                            counter={amplifierCounter++}
                                        />
                                    </div>
                                )
                                : null
                        ))
                        :
                        <>
                            {!isLoading &&
                                <center class="grid__item grid__item--full">
                                    <label><h4>Nenhum amplificador encontrado!</h4></label>
                                </center>
                            }
                        </>
                    }
                </div>
            </div>
            <div className={!hideAmplifier || isLoading ? "hidden" : ""}>
                <div class="info-box info-box--curved info-box--full mt-5" data-status={syncing ? 'buscando' : 'LIGA'}>
                    <div class="info-box__header">
                        {syncing
                            ?
                            <h4 class="info-box__title my-5">SINCRONIZANDO MODEM</h4>
                            :
                            <h4 class="info-box__title my-5">MODEM NÃO ENCONTRADO</h4>
                        }
                    </div>
                    <div class="info-box__body my-5">
                        { syncing ?
                            <i className={`fa fa-usb fa-4x fa-rotate-270 color-info`} aria-hidden="true" ></i>
                        :
                            <i className={`df df-xl df-modem-off color-danger`} aria-hidden="true" ></i>
                        }
                    </div>
                    <div class="info-box__footer my-5">
                        {syncing
                            ?
                            <span>
                                Sincronizando o modem USB. Por favor, aguarde enquanto a conexão é estabelecida.<br />
                                Após a sincronização, a tela de amplificadores ficará disponível.
                            </span>
                            :
                            <span>
                                Não foi possível estabelecer conexão com o modem USB.<br />
                                Verifique o modem e, caso necessário, reinicie o Guardião 2.0.
                            </span>
                        }
                        
                    </div>
                </div>

            </div>

        </main>
    )
}