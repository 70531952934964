import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Dialog from '@mui/material/Dialog'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import AlarmCard from './AlarmCard'
import EventCard from './EventCard'
import { Grid } from '@mui/material'
import makeStyles from "@mui/styles/makeStyles"
import AlarmAudio from '../assets/Alarm.mp3'
import Beep from '../assets/Beep.mp3'
import PanelCommands from './PanelCommands'
import commandsProvider from '../providers/CommandsProvider'
import EventCommandEnum from '../enums/EventCommandEnum'
import AlarmCommandEnum from '../enums/AlarmCommandEnum'
import Event from '../models/Event'
import Alarm from '../models/Alarm'
import PlayedMediaControl from '../models/PlayedMediaControl'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
});

export default function AlarmBackdrop(props) {
    const _location = useLocation();

    const [open, setOpen] = useState(false);
    const [clearBackdrop, setClearBackdrop] = useState(null);
    const [alarms, setAlarms] = useState([]);
    const [occurrences, setOccurrences] = useState([]);
    const [disabled, setDisabled] = useState(true);
    const [alarmAudio] = useState(new Audio(AlarmAudio));
    const [beepAudio] = useState(new Audio(Beep));

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const checkOpenBackdrop = (occurrence) => {
        let response = false;
        
        console.log("🚀 ~ checkOpenBackdrop ~ occurrence:", occurrence)
        if (occurrence instanceof Alarm)
        {
            if (occurrence.origin !== "Guardião")
            {
                response = true;
            }
            else
            {
                response = false;
            }
        }
        else
        {
            response = true;
        }

        return response;
    }

    const playMedia = (occurrence) => {
        // console.log("🚀 ~ playMedia ~ occurrence:", occurrence)
        let allowOpen = true;

        switch (occurrence.command)
        {
            case AlarmCommandEnum.RESTAURAR:
                //setOccurrences([]);
                //localStorage.removeItem("noACDC");
                //setOccurrences((prev) => [...prev, occurrence]);
                //TODO - Implementar um beep diferente para o Restauro
                break;
            case AlarmCommandEnum.ABANDONO:
            case AlarmCommandEnum.BRIGADA:
            case AlarmCommandEnum.SILENCIAR:
            case EventCommandEnum.DISPARO_MANUAL:
            case EventCommandEnum.DISPARO_POR_FUMACA:
            case EventCommandEnum.DISPARO_POR_CHAMA:
            case EventCommandEnum.DISPARO_POR_GAS:
            case EventCommandEnum.DISPARO_POR_TEMPERATURA:
                alarmAudio.play();
                break;
            default:
                beepAudio.play();
                break;
        }

        if (window.location.pathname.includes("comando"))
        {
            console.log("🚀 ~ playMedia ~ _location.pathname:", window.location.pathname)
            allowOpen = checkOpenBackdrop(occurrence);
        }

        if (!open && allowOpen)
        {
            setOpen(true);
        }

        setDisabled(false);

        commandsProvider.updatePlayedMedia(new PlayedMediaControl(occurrence.id, true));
    }

    const updateState = (state) => {
        console.log("🚀 ~ updateState ~ state:", state);

        const combinedOccurrences = [...state.groupAlarms, ...state.groupEvents];

        // Ordenar de forma decrescente e remover duplicados
        const uniqueOccurrences = combinedOccurrences
            .sort((a, b) => new Date(a.datetime) - new Date(b.datetime))
            .filter((item, index, self) => self.findIndex(i => i.id === item.id) === index);

        // Identificar novos itens
        const newItems = uniqueOccurrences.filter(
            (item) => !occurrences.some((occ) => occ.id === item.id)
        );

        // Tocar som para os novos itens
        newItems.forEach((item) => {
            if (state.playedMediaControl.some(pmc => pmc.id === item.id && !pmc.played))
            {
                playMedia(item)
            }
        });

        // Atualizar estado de occurrences
        setOccurrences(uniqueOccurrences);

        // Atualizar clearBackdrop
        setClearBackdrop(state.clearBackdrop);
    };

    useEffect(() => {
        async function clear() {
            setOccurrences([])
            setOpen(false)
            setDisabled(true)
        }
        clear()
    }, [clearBackdrop])

    useEffect(() => {
        commandsProvider.subscribe(updateState);
        return () => commandsProvider.unsubscribe(updateState);
    }, []);

    useEffect(() => {
        if (occurrences.length === 0) {
            setDisabled(true);
            return;
        }

        // Controle de estados
        occurrences.forEach((occurrence) => {
            if (occurrence.panel === props.serial) {
                // Controle de limite de ocorrências
                if (occurrences.length > 99) {
                    setOccurrences((prev) => prev.slice(1)); // Remove o primeiro item
                }

                // Configuração de estados
                if (disabled) {
                    setDisabled(false);
                }
                if (occurrence.command === AlarmCommandEnum.RESTAURAR) {
                    setDisabled(true);
                }
            }
        });
    }, [occurrences]);


    return (
        <>
            <button type="button" className={`header-action ${!disabled && "header-action--warning header-action--animation"}`} data-action="system-alerts" disabled={disabled} onClick={handleClickOpen}>
                <i class="fa fa-exclamation-triangle fa-lg" aria-hidden="true" style={{ "marginTop": "2px" }}></i>
            </button>

            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}>

                <AppBar sx={{ position: 'absolute' }}>
                    <Toolbar>
                        {process.env.REACT_APP_MODE === 'desktop' &&
                            <Grid justifyContent='space-around' alignItems='center' container spacing={3} paddingTop={"24px"}>
                                <PanelCommands
                                    generatedCommand={props.generatedCommand}
                                    user={props.companyId}
                                    serialNumber={props.serial}
                                    token={props.token}
                                    modemStatus={props.modemStatus}
                                    occurrenceController={props.occurrenceController}
                                    setOccurrenceController={props.setOccurrenceController}
                                />
                            </Grid>
                        }
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                            position="center">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div class="margin-top-drawer"></div>
                <div class="container container--fluid">
                    <div class="grid grid--left grid--stretch" data-grid-4x-columns="5" data-grid-3x-columns="4" data-grid-xl-columns="3" data-grid-md-columns="2" data-grid-xs-columns="1">
                        {occurrences.length > 0
                            ? occurrences.slice(0).reverse().map(occurrence => (
                                <div class="grid__item grid__item--no-grow mb-5" key={occurrence.id} >
                                    { occurrence instanceof Event &&
                                        <EventCard userRole={props.userRole} userId={props.userId} token={props.token} serialNumber={props.serial} row={occurrence} />
                                    }
                                    { occurrence instanceof Alarm &&
                                        <AlarmCard token={props.token} serialNumber={props.serial} setOccurrences={setOccurrences} row={occurrence} />
                                    }
                                </div>
                            ))
                            : null
                        }
                    </div>
                </div>
            </Dialog>
        </>
    );
}