import AlarmCommandEnum from "../enums/AlarmCommandEnum";
import EventCommandEnum from "../enums/EventCommandEnum";

class Group {
    constructor(data) {
        this.id = data.id || null;
        this.panel = data.fK_CENTRAL_GRUPO || data.panel || "";
        this.position = data.vL_CADASTRO || data.position || "";
        this.local = data.dS_LOCALIZACAO || data.local || "";
        this.amplifiers = data.amplifier || data.amplifiers || [];
        this.equipaments = data.register || data.equipaments || [];
        this.status = data.bL_STATUS || data.status || false;
        this.currentAlarm = null;
        this.events = [];
    }
  
    // Verifica se o grupo é ativo
    isActive() {
        return this.status;
    }

    // Verifica se o grupo está em estado de alarme
    isAlarmed() {
        return this.currentAlarm !== null;
    }

    getAlarmIcon() {
        let icon = "";

        switch(this.currentAlarm)
        {
            case AlarmCommandEnum.BRIGADA:
                icon = "df-alarm-brigade";
                break;
            case AlarmCommandEnum.ABANDONO:
                icon = "df-alarm-abandon";
                break;
            case AlarmCommandEnum.SILENCIAR:
                icon = "df-alarm-visual";
                break;
            case AlarmCommandEnum.RESTAURAR:
                icon = "df-alarm-reset";
                break;
            default:
                icon = "";
                break;
        }

        return icon;
    }

    getAlarmLabel() {
        let label = "";

        switch(this.currentAlarm)
        {
            case AlarmCommandEnum.BRIGADA:
                label = "Alarme de Brigada";
                break;
            case AlarmCommandEnum.ABANDONO:
                label = "Alarme de Abandono";
                break;
            case AlarmCommandEnum.SILENCIAR:
                label = "Silenciar Alarme";
                break;
            case AlarmCommandEnum.RESTAURAR:
                label = "Restaurar Sistema";
                break;
            default:
                label = "";
                break;
        }

        return label;
    }

    getAlarmStyle() {
        let color = "";

        switch(this.currentAlarm)
        {
            case AlarmCommandEnum.BRIGADA:
                color = "brigade";
                break;
            case AlarmCommandEnum.ABANDONO:
                color = "abandon";
                break;
            case AlarmCommandEnum.SILENCIAR:
                color = "visual";
                break;
            case AlarmCommandEnum.RESTAURAR:
                color = "reset";
                break;
            default:
                color = "";
                break;
        }

        return color;
    }

    // Retorna se o grupo tem algum acionamento
    // Verifica se há eventos com comandos específicos de disparo
    isTriggered() {
        const triggers = [
            EventCommandEnum.DISPARO_MANUAL, 
            EventCommandEnum.DISPARO_POR_FUMACA
        ];
        return this.events.some(e => triggers.includes(e.command));
    }


    // Verifica se o grupo tem eventos
    hasEvents() {
        return this.events.length > 0;
    }

    // Adiciona um alarme (substitui o existente, se houver)
    setAlarm(alarm) {
        if (Object.values(AlarmCommandEnum).includes(alarm)) 
        {
            this.currentAlarm = alarm;
        } 
        else 
        {
            console.warn(`${alarm} não é um alarme válido.`);
        }
    }

    // Altera lista de eventos recebendo um array de Event
    setEvents(events)
    {
        this.events = events;
    }

    // Altera status
    setStatus(status) {
        this.status = status;
    }

    // Verifica se o grupo está gerando comando
    isBusy() {
        return this.status === "busy";
    }

    // Remove o alarme atual
    clearAlarm() {
        this.currentAlarm = null;
    }

    // Adiciona um evento (sem duplicados)
    addEvent(event) {
        if (Object.values(EventCommandEnum).includes(event) && !this.events.includes(event)) 
        {
            this.events.push(event);
        } 
        else if (!Object.values(EventCommandEnum).includes(event)) 
        {
            console.warn(`${event} não é um evento válido.`);
        }
    }

    // Remove um evento
    removeEvent(event) {
        this.events = this.events.filter(e => e !== event);
    }

    // Remove todos os eventos
    clearEvents() {
        this.events = [];
    }

    getRemovedCount()
    {
        return (this.events.filter(e => e.command === EventCommandEnum.REMOVIDO)).length;
    }

    getLowBatteryCount()
    {
        return (this.events.filter(e => e.command === EventCommandEnum.BATERIA_BAIXA)).length;
    }

    getWithoutEnergyCount()
    {
        return (this.events.filter(e => e.command === EventCommandEnum.SEM_REDE_AC)).length;
    }

    getEnvironmentalControlCount()
    {
        return (this.events.filter(e => e.command === EventCommandEnum.CONTROLE_AMBIENTAL)).length;
    }

    getOtherEventsCount(){
        const mainEvents = [
            EventCommandEnum.REMOVIDO, 
            EventCommandEnum.BATERIA_BAIXA, 
            EventCommandEnum.SEM_REDE_AC, 
            EventCommandEnum.DISPARO_MANUAL, 
            EventCommandEnum.DISPARO_POR_FUMACA 
        ];

        return (this.events.filter(e => !mainEvents.includes(e.command))).length;
    }

    getManualTriggeredCount()
    {
        return (this.events.filter(e => e.command === EventCommandEnum.DISPARO_MANUAL)).length;
    }
    
    getSmokeTriggeredCount()
    {
        return (this.events.filter(e => e.command === EventCommandEnum.DISPARO_POR_FUMACA)).length;
    }

    // Retorna o total de amplificadores
    getAmplifierCount() {
        return this.amplifiers.length;
    }

    // Retorna o total de equipamentos
    getEquipamentsCount() {
        return this.equipaments.length;
    }

    // Retorna o nome do Local
    getGroupLocal() {
        if (this.local === "Geral") {
            return "Geral";
        }
        return `${this.position.slice(12)} - ${this.local || "Sem Nome Regist."}`;
    }

    // Retorna o objeto Group no formato JSON
    toJSON() {
        return {
            id: this.id,
            local: this.local,
            panel: this.panel,
            position: this.position,
            equipments: this.equipments,
            amplifiers: this.amplifiers,
            status: this.status,
            currentAlarm: this.currentAlarm,
            events: this.events,
        };
    }
}

export default Group;